 <template>
  <Form :model="rightData" :label-colon="true">
    <FormItem label="标题">
      <Input
        :disabled="disabledForm"
        :maxlength="200"
        v-model.trim="rightData.title"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
    <FormItem label="填写说明">
      <Input
        :maxlength="200"
        :disabled="disabledForm"
        v-model.trim="rightData.placeholder"
        placeholder="对标题进一步描述说明"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
    <FormItem label="选项设置">
      <Checkbox
        v-model="rightData.vertical"
        @on-change="changeItemInfo"
        :disabled="disabledForm"
        >换行显示</Checkbox
      >
    </FormItem>
    <Table
      :columns="optionColumns"
      :data="rightData.optionList"
      border
      class="optionTable"
      no-data-text="暂无选项"
    >
      <template slot-scope="{ row }" slot="label">
        <Input
          v-model.trim="row.label"
          :maxlength="100"
          @on-change="changeItemInfo(row)"
          :disabled="disabledForm"
        ></Input>
      </template>
      <template slot-scope="{ row }" slot="person">
        <Input
          v-model.trim="row.person"
          :maxlength="10"
          @on-change="changeItemInfo(row)"
          :disabled="disabledForm"
          type="number"
        ></Input>
      </template>
    </Table>
    <Button
      icon="ios-settings-outline"
      type="primary"
      @click="linkage"
      :disabled="false"
      >联动设置</Button
    >
    <FormItem label="限制多选条数">
       <InputNumber
       :disabled="disabledForm"
        :max="rightData.optionList.length"
        v-model.trim="rightData.maxLength"
        @on-change="changeItemInfo"
        type="number"
        ></InputNumber>
    </FormItem>
    <FormItem>
      <Checkbox
        v-model="rightData.required"
        @on-change="changeItemInfo"
        :disabled="disabledForm"
        >必填</Checkbox
      >
    </FormItem>

    <!-- 联动设置 -->
    <Modal
      title="字段联动设置"
      width="800"
      v-model="linkageStatus"
      :styles="{ top: '80px' }"
      @on-ok="saveLinkAge"
    >
      <Button
        icon="ios-add-circle-outline"
        type="primary"
        @click="addLinkAge"
        :disabled="disabledForm"
        style="margin-bottom: 10px"
        id="CheckboxCom"
        >添加</Button
      >
      <LiefengTable
        :tableData="linkAgeArr"
        :talbeColumns="talbeColumns"
        v-if="linkageStatus"
        height="500"
        :hidePage="true"
      ></LiefengTable>
    </Modal>
  </Form>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: ["rightData", "mainList", "clickCurrentIndex", "disabledForm"],
  data() {
    return {
      // 联动结果
      linkageResultArr: [],
      linkageStatus: false,
      optionColumns: [
        {
          title: "选项标签",
          slot: "label",
        },
        {
          title: "人数限制",
          slot: "person",
          width: 80,
        },
        {
          title: "操作",
          width: 70,
          align: "center",
          renderHeader: (h, params) => {
            return h("div", [
              h("span", "操作"),
              h("Icon", {
                props: {
                  type: "ios-add-circle",
                },
                style: {
                  marginLeft: "6px",
                  cursor: "pointer",
                  color: "#2d8cf0",
                  fontSize: "18px",
                },
                on: {
                  click: () => {
                    if (this.disabledForm) return;
                    this.rightData.optionList.push({
                      label: "",
                      person: "",
                      id: this.$core.randomString(),
                    });
                    this.changeItemInfo();
                  },
                },
              }),
            ]);
          },
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: "ios-trash",
              },
              style: {
                cursor: "pointer",
                fontSize: "18px",
                color: "#ed4014",
              },
              on: {
                click: () => {
                  if (this.disabledForm) return;
                  if (this.rightData.optionList.length <= 2) {
                    this.$Message.info({
                      background: true,
                      content: "至少保留两个选项"
                    })
                  } else {
                    this.rightData.optionList.splice(params.index, 1);
                  }
                  this.changeItemInfo();
                },
              },
            });
          },
        },
      ],
      talbeColumns: [
        {
          title: "所选值",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.value,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].value = val;
                  },
                },
              },
              [
                this.rightData.optionList.map((item, index) => {
                  return h(
                    "Option",
                    {
                      props: {
                        value: item.id,
                      },
                    },
                    item.label
                  );
                }),
              ]
            );
          },
        },
        {
          title: "联动题目",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.title,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].title = val;
                  },
                },
              },
              [
                this.mainList.map((item, index) => {
                  if (index != this.clickCurrentIndex) {
                    return h(
                      "Option",
                      {
                        props: {
                          value: item.id,
                          disabled: (() => {
                            let bool = false;
                            this.linkAgeArr.map((subItem) => {
                              if (subItem.title == item.id) {
                                bool = true;
                              }
                            });
                            return bool;
                          })(),
                        },
                      },
                      item.title
                    );
                  }
                }),
              ]
            );
          },
        },
        {
          title: "联动结果",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.result,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].result = val;
                  },
                },
              },
              [
                this.linkageResultArr.map((item, index) => {
                  return h(
                    "Option",
                    {
                      props: {
                        value: item.value,
                        disabled: (() => {
                          let result = this.linkAgeArr.some((subItem,subIndex) => {
                            if(subIndex != params.index) {
                              if(subItem.value == params.row.value) {
                                return item.value != subItem.result;
                              }
                            }else {
                              return false;
                            }
                          });
                          return result 
                        })()
                      },
                    },
                    item.label
                  );
                }),
              ]
            );
          },
        },
        {
          title: "操作",
          align: "center",
          width: 80,
          render: (h,params) => {
            return h("Icon", {
              props: {
                type: "ios-trash",
              },
              style: {
                cursor: "pointer",
                fontSize: "18px",
                color: "#ed4014",
              },
              on: {
                click: () => {
                  this.linkAgeArr.splice(params.index,1);
                }
              }
            })
          }
        }
      ],
      //   临时存储联动设置数据，点击保存时再添加到mainList中
      linkAgeArr: [],
    };
  },
  methods: {
    changeItemInfo(row) {
      if (row && row._index != undefined) {
        //此处为了解决选项数据的双向数据绑定
        this.rightData.optionList.splice(row._index, 1, row);
      }
      this.$emit("changeItemInfo", this.rightData);
    },
    //   保存联动设置
    saveLinkAge() {
      this.rightData.linkAgeArr = JSON.parse(JSON.stringify(this.linkAgeArr));
      this.changeItemInfo();
    },
    //联动设置
    linkage() {
      this.linkAgeArr = JSON.parse(JSON.stringify(this.rightData.linkAgeArr));
      this.linkageStatus = true;
    },
    //添加联动设置
    addLinkAge() {
      this.linkAgeArr.push({
        value: '',
        title: '',
        result: '',
      });
    },
  },
  created() {
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "FORM_LINKAGE_ACTION",
    }).then((res) => {
      if (res.code == 200) {
        this.linkageResultArr = res.dataList.map((item) => {
          return {
            value: item.dictKey,
            label: item.dictValue,
          };
        });
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.option {
  margin: 10px 0;
  & > .ivu-form-item-label {
    width: 100%;
    text-align: left;
  }
  & > .ivu-form-item-content {
    border: 1px dashed #ccc;
    padding: 10px;
    span {
      width: 140px;
      display: inline-block;
    }
  }
}
.optionTable {
  margin: 10px 0;
  /deep/.ivu-table-cell {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>