<template>
  <Form :model="rightData" :label-colon="true" :disabled="disabledForm">
    <FormItem label="标题">
      <Input
        v-model.trim="rightData.title"
        @on-change="changeItemInfo"
        :maxlength="200"
      ></Input>
    </FormItem>
    <FormItem label="填写说明">
      <Input
        :maxlength="200"
        v-model.trim="rightData.placeholder"
        placeholder="进一步描述标题说明"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
    <FormItem>
      <div slot="label">
        选项设置:<span style="color: #aaa">(换行设置多个选项内容)</span>
      </div>
      <Input
        type="textarea"
        :rows="4"
        :maxlength="1000"
        v-model="textarea"
        placeholder="下拉选项"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
    <Button
      icon="ios-settings-outline"
      type="primary"
      @click="linkage"
      :disabled="false"
      >联动设置</Button
    >
    <FormItem>
      <Checkbox v-model="rightData.required" @on-change="changeItemInfo"
        >必填</Checkbox
      >
    </FormItem>
    <!-- <FormItem>
      <Checkbox v-model="rightData.multiple" @on-change="changeItemInfo"
        >可多选</Checkbox
      >
    </FormItem> -->
    <FormItem>
      <Checkbox v-model="rightData.search" @on-change="changeItemInfo"
        >可搜索</Checkbox
      >
    </FormItem>

    <!-- 联动设置 -->
    <Modal
      title="字段联动设置"
      width="800"
      v-model="linkageStatus"
      :styles="{ top: '80px' }"
      @on-ok="saveLinkAge"
    >
      <Button
        icon="ios-add-circle-outline"
        type="primary"
        @click="addLinkAge"
        :disabled="disabledForm"
        style="margin-bottom: 10px"
        id="SelectCom"
        >添加</Button
      >
      <LiefengTable
        :tableData="linkAgeArr"
        :talbeColumns="talbeColumns"
        v-if="linkageStatus"
        height="500"
        :hidePage="true"
      ></LiefengTable>
    </Modal>
  </Form>
</template>

<script>
import LiefengTable from '@/components/LiefengTable'
export default {
  props: ["rightData", "disabledForm", "mainList", "clickCurrentIndex"],
  data() {
    return {
      textarea: "",
      textareaId: [], //修改回显时，先保存textarea的id，否则保存时后端会丢失数据

      // 联动结果
      linkageResultArr: [],
      linkageStatus: false,
     
      talbeColumns: [
        {
          title: "所选值",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.value,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].value = val;
                  },
                },
              },
              [
                this.rightData.optionList.map((item, index) => {
                  return h(
                    "Option",
                    {
                      props: {
                        value: item.id,
                        disabled: (() => {
                          let bool = false;
                          if (this.rightData.multiple === false) {
                            //单选
                            this.linkAgeArr.map((subItem) => {
                              if (
                                this.linkAgeArr[params.index].title &&
                                this.linkAgeArr[params.index].title ==
                                  subItem.title &&
                                subItem.value == item.id
                              ) {
                                bool = true;
                              }
                            });
                          }
                          return bool;
                        })(),
                      },
                    },
                    item.label
                  );
                }),
              ]
            );
          },
        },
        {
          title: "联动题目",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.title,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].title = val;
                  },
                },
              },
              [
                this.mainList.map((item, index) => {
                  if (index != this.clickCurrentIndex) {
                    return h(
                      "Option",
                      {
                        props: {
                          value: item.id,
                          disabled: (() => {
                            let bool = false;
                            if (this.rightData.multiple) {
                              //多选
                              this.linkAgeArr.map((subItem) => {
                                if (subItem.title == item.id) {
                                  bool = true;
                                }
                              });
                            } else {
                              //单选
                              this.linkAgeArr.map((subItem) => {
                                if (
                                  this.linkAgeArr[params.index].value &&
                                  this.linkAgeArr[params.index].value ==
                                    subItem.value &&
                                  subItem.title == item.id
                                ) {
                                  bool = true;
                                }
                              });
                            }

                            return bool;
                          })(),
                        },
                      },
                      item.title
                    );
                  }
                }),
              ]
            );
          },
        },
        {
          title: "联动结果",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  transfer: true,
                  disabled: this.disabledForm,
                  value: params.row.result,
                },
                on: {
                  "on-change": (val) => {
                    this.linkAgeArr[params.index].result = val;
                  },
                },
              },
              [
                this.linkageResultArr.map((item, index) => {
                  return h(
                    "Option",
                    {
                      props: {
                        value: item.value,
                        disabled: (() => {
                          let result = this.linkAgeArr.some((subItem,subIndex) => {
                            if(subIndex != params.index) {
                              if(subItem.value == params.row.value) {
                                return item.value != subItem.result;
                              }
                            }else {
                              return false;
                            }
                          });
                          return result 
                        })()
                      },
                    },
                    item.label
                  );
                }),
              ]
            );
          },
        },
        {
          title: "操作",
          align: "center",
          width: 80,
          render: (h,params) => {
            return h("Icon", {
              props: {
                type: "ios-trash",
              },
              style: {
                cursor: "pointer",
                fontSize: "18px",
                color: "#ed4014",
              },
              on: {
                click: () => {
                  this.linkAgeArr.splice(params.index,1);
                }
              }
            })
          }
        }
      ],
      //   临时存储联动设置数据，点击保存时再添加到mainList中
      linkAgeArr: [],
    };
  },
  watch: {
    textarea(newV, oldV) {
      if (newV !== oldV) {
        let arr = newV.split("\n");
        let length = 0;
        this.rightData.optionList = [];
        arr.map((item, index) => {
          if (this.textareaId.length > 0) { //证明此事是回显数据
            if(this.textareaId.length >= arr.length) {
              this.rightData.optionList.push({
                value: (length += 1),
                label: item,
                id: this.textareaId[index],
              });
            }else {
              let randomString = this.$core.randomString();
               this.rightData.optionList.push({
                value: (length += 1),
                label: item,
                id: randomString,
              });
              this.textareaId.push(randomString)
            }
            
          } else {
            if (item != "" && item != undefined && item != null) {
              this.rightData.optionList.push({
                value: (length += 1),
                label: item,
                id: this.$core.randomString(),
              });

            }
          }
        });
      }
    },
  },
  methods: {
    changeItemInfo() {
      this.$emit("changeItemInfo", this.rightData);
    },
    //   保存联动设置
    saveLinkAge() {
      this.rightData.linkAgeArr = JSON.parse(JSON.stringify(this.linkAgeArr));
      this.changeItemInfo();
    },
    //联动设置
    linkage() {
      this.linkAgeArr = JSON.parse(JSON.stringify(this.rightData.linkAgeArr));
      this.linkageStatus = true;
    },
    //添加联动设置
    addLinkAge() {
      this.linkAgeArr.push({
        value: "",
        title: "",
        result: "",
      });
    },
  },
  components: {
    LiefengTable
  },
  created() {
    if (this.rightData.optionList && this.rightData.optionList.length > 0) {
      let arr = [];
      this.textareaId = [];
      this.rightData.optionList.map((item) => {
        arr.push(item.label);
        this.textareaId.push(item.id);
      });
      this.textarea = arr.join("\n");
    }
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "FORM_LINKAGE_ACTION",
    }).then((res) => {
      if (res.code == 200) {
        this.linkageResultArr = res.dataList.map((item) => {
          return {
            value: item.dictKey,
            label: item.dictValue,
          };
        });
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
  },
};
</script>
    
<style scoped lang='less'>
</style>