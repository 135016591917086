<template>
    <Form :model="rightData" :label-colon="true" :disabled="disabledForm">
    <FormItem label="标题">
      <Input v-model.trim="rightData.title" :maxlength="200" @on-change="changeItemInfo"></Input>
    </FormItem>
    <FormItem label="填写说明">
      <Input
      :maxlength="200"
        v-model.trim="rightData.placeholder"
        placeholder="对标题进一步描述说明" 
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
     <FormItem>
      <Checkbox v-model="rightData.multiple" @on-change="changeItemInfo('date')">是否多选</Checkbox><Icon type="ios-help-circle-outline" title="是否可以选择多个文件上传" />
    </FormItem>
    <FormItem label="最多上传个数" v-if="rightData.multiple">
        <InputNumber  :min="1" :max="99" v-model.trim="rightData.max" :precision="0" @on-change="changeItemInfo"></InputNumber>
    </FormItem>
    <FormItem label="限制大小（M）">
        <InputNumber  :min="1" :max="99999999" v-model.trim="rightData.limit" :precision="0" @on-change="changeItemInfo"></InputNumber>
    </FormItem>
    <FormItem label="秘密情况">
        <RadioGroup v-model="rightData.secret" @on-change="changeItemInfo">
            <Radio label="个人">个人</Radio>
            <Radio label="公开">公开</Radio>
        </RadioGroup>
    </FormItem>
     <FormItem>
      <Checkbox v-model="rightData.required" @on-change="changeItemInfo">必填</Checkbox>
    </FormItem>
    </Form>
</template>

<script>
   export default {
       props: ['rightData',"disabledForm"],
       data() {
            return {
              
           }
        },
        methods: {
             changeItemInfo() {
                this.$emit("changeItemInfo", this.rightData);
            }
        }
      
    }
</script>
    
<style scoped lang='less'>
    
</style>