<template>
    <div class="container">
        <!-- 背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <Mymodel :fullscreen="false" :value="toggle1" :toggle="true" :textObj="textObj" title="文本预览" :toggle-fn2="toggleFn2"></Mymodel>
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>
        <div class="menu">
            <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
                <MenuItem name="baseInfo">
                    <Icon type="ios-information-circle" />
                    基本信息
                </MenuItem>
                <MenuItem name="setInfo">
                    <Icon type="ios-settings" />
                    活动设置
                </MenuItem>
                <MenuItem name="writeInfo" v-if="addChangeIden || (!addChangeIden && formData.whetherDynamicForm == '1' && Object.keys(formDetailVo).length)">
                    <Icon type="ios-create" />
                    填写表单设置
                </MenuItem>
                <MenuItem name="moreSetting">
                    <Icon type="md-chatbubbles" />
                    更多设置
                </MenuItem>
            </Menu>
        </div>
        <div class="content">
            <Form :model="formData" :label-colon="true" :label-width="120" v-show="showMenu == 'baseInfo'">
                <FormItem>
                    <span slot="label" class="validate">标题:</span>
                    <Input v-model.trim="formData.title" :maxlength="100"></Input>
                </FormItem>
                <FormItem label="封面图片">
                    <span slot="label" class="validate">封面图片:</span>
                    <img ref="image" :src="formData.photo" alt="" width="200" style="display: block; margin-bottom: 10px" />
                    <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                    <Upload
                        ref="imgUpload"
                        :before-upload="beforeUpload"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        style="display: inline-block"
                        :on-format-error="imageError"
                        :on-progress="imageProgress"
                        :on-success="(value, file) => imageSuccess(file)"
                        :action="action || (uploadData && uploadData.host && uploadData.host != '' ? uploadData.host : '')"
                        :data="uploadData"
                        :on-remove="imageRemove"
                        :show-upload-list="false"
                    >
                        <Button icon="ios-cloud-upload-outline" type="info">上传图片</Button>
                    </Upload>
                    <Button @click="selectTemplate" style="margin-left: 10px">选择模板</Button>
                    <Button v-if="formData.photo" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">公布时间:</span>
                    <DatePicker :options="dateOption" :transfer="true" v-model="formData.time" type="datetimerange" style="width: 400px" placeholder="请选择公布开始时间和结束时间"></DatePicker>
                    <Tooltip placement="top" :delay="500">
                        <Icon type="md-help-circle" style="margin: 10px"></Icon>
                        <div slot="content">
                            <p>到了公布开始时间，终端上才可查看到；</p>
                            <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
                        </div>
                    </Tooltip>
                </FormItem>

                <FormItem>
                    <span slot="label" class="validate">发布终端:</span>
                    <CheckboxGroup v-model="formData.terminal" @on-change="allSelect">
                        <Checkbox label="0">全部</Checkbox>
                        <Checkbox label="1">TV</Checkbox>
                        <!-- <Checkbox label="2">PC</Checkbox> -->
                        <Checkbox label="3">APP</Checkbox>
                        <Checkbox label="4">小程序</Checkbox>
                        <!-- <Checkbox label="5">公众号</Checkbox> -->
                        <!-- <Tooltip placement="top" :delay="500">
              <Icon type="md-help-circle" style="margin: 10px"></Icon>
              <div slot="content">
                <p>
                  勾选公众号，则会对发布范围中关注过社区随约服务网上驿站公众号的用户主动推送此活动。
                </p>
              </div>
            </Tooltip> -->
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="发布范围">
                    <span slot="label" class="validate">发布范围</span>
                    <Select v-model="prov" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择省" multiple filterable @on-change="changeProv">
                        <Option :value="item.value" v-for="(item, index) in proList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="city" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择市" multiple :disabled="cityDisabled" filterable @on-change="changeCity">
                        <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="region" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择区" :disabled="regionDisabled" multiple filterable @on-change="changeRegion">
                        <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="stree" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择街道/乡镇" :disabled="streeDisabled" multiple filterable @on-change="changeStree">
                        <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="project" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择社区" :disabled="projectDisabled" multiple filterable>
                        <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="联系电话">
                    <Input v-model.trim="formData.contactMobile" style="width: 200px" :maxlength="20"></Input>
                    <span style="color: #aaa; width: 200px; margin-left: 16px">说明：移动端可以直接拨打</span>
                </FormItem>
                <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=活动详情（必填）"
          style="width: 100%; height: 500px; border: 0px"
          @load="loadUeditor"
        ></iframe> -->
                <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=活动详情（必填）" style="width: 100%; height: 700px; border: 0px" @load="loadUeditor"></iframe>
            </Form>
            <Form :model="formData" :label-colon="true" :label-width="130" v-show="showMenu == 'setInfo'">
                <FormItem>
                    <span slot="label" class="validate">活动类型</span>
                    <RadioGroup v-model="formData.appointmentType" @on-change="changeApponintType">
                        <Radio label="1" :disabled="editDisabled">一次性活动</Radio>
                        <Radio label="2" :disabled="editDisabled">一键登记</Radio>
                        <Radio label="3" :disabled="editDisabled">周期性活动</Radio>
                        <Radio label="4" :disabled="editDisabled">排号</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">开展日期:</span>
                    <DatePicker
                        v-if="formData.appointmentType != '1'"
                        :options="formData.appointmentType == '1' ? dateOptionOne : dateOption"
                        :transfer="true"
                        :disabled="disabledStatus || editDisabled"
                        v-model="formData.time2"
                        type="daterange"
                        style="width: 400px"
                        placeholder="请选择开展日期"
                        @on-change="changeTime2"
                    ></DatePicker>
                    <DatePicker
                        v-else
                        :options="formData.appointmentType == '1' ? dateOptionOne : dateOption"
                        :transfer="true"
                        :disabled="disabledStatus || editDisabled"
                        v-model="formData.time2[0]"
                        type="date"
                        style="width: 400px"
                        placeholder="请选择开展日期"
                        @on-change="
                            val => {
                                this.changeTime2(val, 'time')
                            }
                        "
                    ></DatePicker>
                    <span style="color: #aaa; margin-left: 16px">注：整个活动具体开展的日期范围，长期活动需要选择长一点的日期</span>
                </FormItem>
                <FormItem v-if="formData.appointmentType == '1'">
                    <span slot="label" class="validate">预约具体时段:</span>
                    <RadioGroup v-model="ruleVos[3].ruleValue" @on-change="$forceUpdate()">
                        <Radio label="0" :disabled="editDisabled">否</Radio>
                        <Radio label="1" :disabled="editDisabled">是</Radio>
                        <span style="color: #aaa; margin-left: 16px">注：“否”则不需要设置具体的开展时段，适用于课程学员招募等</span>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <span slot="label" class="validate">重复开展:</span>
                    <Select v-model="formData.repetition" style="width: 200px" @on-change="cycleDates = []">
                        <!-- <Option value="0" :disabled="disabledNorepetition">不重复</Option> -->
                        <Option value="1" :disabled="disabledRepetition || editDisabled">按月</Option>
                        <Option value="2" :disabled="disabledRepetition || editDisabled">按周</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="(formData.repetition == '1' || formData.repetition == '2') && (formData.appointmentType == '3' || formData.appointmentType == '4')">
                    <span slot="label" class="validate">重复内容:</span>
                    <CheckboxGroup v-model="cycleDates" v-if="formData.repetition == '1'" class="dateTime">
                        <Checkbox :label="item.value" border v-for="item in cycleDateList" :key="item.value" :disabled="editDisabled">{{ item.label }}</Checkbox>
                    </CheckboxGroup>
                    <span style="color: #aaa; margin-left: 16px" v-if="formData.repetition == '1'">注：默认每月1号放下月的号</span>
                    <CheckboxGroup v-model="cycleDates" v-if="formData.repetition == '2'" class="dateWeek">
                        <Checkbox :label="item.value" border v-for="item in cycleTimeList" :key="item.value" :disabled="editDisabled">{{ item.label }}</Checkbox>
                    </CheckboxGroup>
                    <span style="color: #aaa; margin-left: 16px" v-if="formData.repetition == '2'" :disabled="editDisabled">注：默认每周日0点放下周的号</span>
                </FormItem>
                <FormItem v-if="(formData.appointmentType == '1' && ruleVos[3].ruleValue == '1') || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <span slot="label" class="validate">开展时段:</span>
                    <div style="display: inline-block; width: 490px">
                        <div v-for="(item, index) in cycleTimes" :key="index" style="margin-bottom: 10px">
                            <TimePicker
                                v-model="item.timeValue"
                                :editable="false"
                                format="HH:mm"
                                type="timerange"
                                placement="bottom-end"
                                placeholder="请选择开展时段"
                                style="width: 200px"
                                :disabled="editDisabled"
                            ></TimePicker>
                            <span style="margin-left: 16px">限名额数：</span>
                            <InputNumber placeholder="不限则不用填" :disabled="editDisabled" v-model="item.limits[0].attrValue" style="width: 200px" :max="9999999999" :min="1"></InputNumber>
                        </div>
                    </div>
                    <Button :disabled="editDisabled" icon="ios-add" type="primary" style="margin-left: 20px" @click="addCycleTimes"></Button>
                    <Button :disabled="editDisabled" icon="ios-trash-outline" type="error" style="margin-left: 10px" @click="delCycleTimes"></Button>
                    <span style="color: #aaa; margin-left: 16px">注：按时段限制报名人数，一天多个时段请点添加“+”</span>
                </FormItem>
                <FormItem v-if="(formData.appointmentType == '1' && ruleVos[3].ruleValue == '1') || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <span slot="label" class="validate">可报名多个时段:</span>
                    <RadioGroup v-model="ruleVos[4].ruleValue">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                        <span style="color: #aaa; margin-left: 16px">注：“否”则限制用户在所有开展日期内只能选择一个时段进行报名</span>
                    </RadioGroup>
                </FormItem>

                <!-- <FormItem v-if="ruleVos[3].ruleValue == '1' && formData.appointmentType == '1'">
          <span slot="label" class="validate">生成排号:</span>
          <RadioGroup v-model="ruleVos[5].ruleValue">
            <Radio label="0">否</Radio>
            <Radio label="1">是</Radio>
            <span style="color: #aaa; margin-left: 16px"
            >注：可生成唯一性排号，用于现场排队</span
            >
          </RadioGroup>
        </FormItem> -->

                <FormItem>
                    <span slot="label" class="validate">可代报名:</span>
                    <RadioGroup v-model="formData.whetherRegistration" @on-change="formData.registrationType = ''">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                        <span style="color: #aaa; margin-left: 16px">注：代报名及本人报名均包含在人数限制内</span>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="formData.whetherRegistration == '1'">
                    <span slot="label" class="validate">代报填写信息:</span>
                    <Select v-model="formData.registrationType" style="width: 200px">
                        <Option value="1">姓名手机号码</Option>
                        <Option value="2">姓名手机号码身份证</Option>
                    </Select>
                    <span style="color: #aaa; margin-left: 16px">注：代别人报名时需要额外填写别人的内容</span>
                </FormItem>
                <FormItem v-if="(ruleVos[3].ruleValue == '1' && formData.appointmentType == '1') || formData.appointmentType == '3'">
                    <span slot="label" class="validate">需签到:</span>
                    <RadioGroup v-model="formData.whetherSign">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                        <span style="color: #aaa; margin-left: 16px">注：一般到现场参与的活动需要签到</span>
                    </RadioGroup>
                </FormItem>

                <FormItem v-if="formData.appointmentType == '1' || formData.appointmentType == '3'">
                    <span slot="label" class="validate">党员活动:</span>
                    <RadioGroup v-model="formData.whetherCrypto">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                        <span style="color: #aaa; margin-left: 16px">注：可计算党员参与的活动次数，需要先做党员管理</span>
                    </RadioGroup>
                </FormItem>
                <!-- <FormItem>
          <span slot="label">推送方式:</span>
          <Checkbox v-model="formData.pushChannel">小程序订阅通知</Checkbox>
        </FormItem> -->

                <FormItem v-if="formData.appointmentType == '2'">
                    <span slot="label" class="validate">重复开展:</span>
                    <RadioGroup v-model="ruleVos[6].ruleValue">
                        <Radio label="1">全程一次</Radio>
                        <Radio label="2">每天一次</Radio>
                        <Radio label="3">不限次数</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem label="人数限制" v-if="ruleVos[3].ruleValue == '0' && formData.appointmentType == '1'">
                    <InputNumber v-model.trim="ruleVos[7].ruleValue" style="width: 200px" :max="9999999999" :min="0" placeholder="人数限制"></InputNumber>
                    <span style="margin-left: 10px">人</span>
                </FormItem>
                <FormItem label="人均费用" v-if="formData.appointmentType == '1' || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <InputNumber v-model.trim="formData.perCapita" style="width: 200px" :max="9999999999" :min="0"></InputNumber>
                    <span style="margin-left: 10px">元</span>
                </FormItem>
                <FormItem label="活动地点" v-if="formData.appointmentType == '1' || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <Input v-model.trim="formData.addr" :maxlength="30"></Input>
                </FormItem>
                <FormItem label="活动范围" v-if="formData.appointmentType == '1' || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <Input v-model.trim="formData.range" :maxlength="30"></Input>
                </FormItem>
                <FormItem label="集合地点" v-if="formData.appointmentType == '1' || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <Input v-model.trim="formData.venue" :maxlength="30"></Input>
                </FormItem>
                <FormItem label="集合时间" v-if="formData.appointmentType == '1' || formData.appointmentType == '3' || formData.appointmentType == '4'">
                    <DatePicker :editable="false" v-model="formData.venueTime" type="datetime" placeholder="请选择集合时间" style="width: 200px"></DatePicker>
                </FormItem>
            </Form>
            <Form :model="formData" v-show="showMenu == 'writeInfo'">
                <FormItem style="margin: 0 0 16px 20px">
                    <Checkbox v-model="formData.selectForm" :true-value="true" :false-value="false" v-if="addChangeIden"><span style="color: red">需要通过表单采集更多信息</span></Checkbox>
                    <span style="margin-left: 16px; color: #aaa" v-if="addChangeIden">注：通过实名认证可获得姓名、性别、年龄、手机号；若想采集更多信息则需引用表单采集</span>
                    <div class="cententbtn" v-if="!formData.selectForm">
                        <div class="cover"></div>
                        <Checkbox v-model="formData.selectForm"><span style="color: red">+添加表单</span></Checkbox>
                    </div>

                    <div style="float: right; margin-right: 10px">
                        <Button type="primary" style="margin-right: 10px" v-if="formData.selectForm && addChangeIden" @click="openFormTemp">引用模板</Button>
                        <Button type="primary" v-if="formData.selectForm" @click="openOnlyPreview">预览</Button>
                    </div>
                </FormItem>
                <!-- 表单模板 -->
                <div style="margin: 0 10px">
                    <AddForm v-if="formData.selectForm && changeFormShow === false" :hiddenTitle="true" ref="addChangeForm" :formDetailVo2="formDetailVo"></AddForm>
                    <ChangeForm v-if="formData.selectForm && changeFormShow === true" :hiddenTitle="true" :infoId="infoId" :formDetailVo2="formDetailVo" ref="addChangeForm"></ChangeForm>
                </div>
            </Form>
            <Form :model="formData" v-show="showMenu == 'moreSetting'" :label-colon="true" :label-width="120">
                <FormItem>
                    <span slot="label" class="validate">信息来源:</span>
                    <Input v-model.trim="formData.source" :maxlength="20"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">身份校验:</span>
                    <RadioGroup v-model="ruleVos[0].ruleValue">
                        <div>
                            <Radio label="1">
                                实名认证
                                <span style="color: #aaa; margin-left: 16px">注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，已认证的用户无需重复认证。</span>
                            </Radio>
                        </div>
                        <div>
                            <Radio label="2">
                                网上家园认证
                                <span style="color: #aaa; margin-left: 16px">注：勾选后可获填报用户的姓名、手机号，已认证的用户无需重复认证</span>
                            </Radio>
                        </div>
                        <div>
                            <Radio label="">
                                不认证
                                <span style="color: #aaa; margin-left: 16px">注：不认证则只可获得报名用户的手机号</span>
                            </Radio>
                        </div>
                    </RadioGroup>
                    <!-- <Checkbox v-model="ruleVos[0].ruleValue">实名认证</Checkbox>
          <span style="color: #aaa; margin-left: 16px"
            >注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，不勾选则只可获得报名用户的手机号。</span
          > -->
                </FormItem>
                <FormItem label="参与获得积分">
                    <InputNumber style="width: 200px" :max="999999999" :min="0" v-model="ruleVos[1].ruleValue"></InputNumber>
                </FormItem>
                <FormItem label="小程序订阅通知">
                    <CheckboxGroup v-model="pushType" @on-change="changePushType">
                        <Checkbox label="1">我的用户</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                        <br />
                        <Checkbox label="2">我的管理员</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                        <br />
                        <!-- <Checkbox label="3">我的畅联组</Checkbox>
                        <span v-if="pushType && pushType[0] == 3">
                            <Input style="width: 300px; margin-left: 16px" v-model="formData.groupNames" />
                            <Button type="primary" @click="groupStatus = true">选群</Button>
                        </span>
                        <br /> -->
                        <!-- <Checkbox label="4" v-if="dimensionId == 2 || buttonRoot == '1003'">社区线上用户</Checkbox>
                        <span v-if="dimensionId == 2 || buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span> -->
                        <Checkbox v-if="dimensionId == 2 || this.buttonRoot == '1003'" label="5">社区注册用户</Checkbox>
                        <span v-if="dimensionId == 2 || this.buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                            注：勾选后，订阅了社区通知且 激活了社区（村）地址的居民用户可收到推送信息。
                        </span>
                        <br />
                        <Checkbox label="4" v-if="dimensionId == 2 || this.buttonRoot == '1003'">社区访问用户</Checkbox>
                        <span v-if="dimensionId == 2 || this.buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                            注：勾选后，订阅了社区通知的所有访问过本社区的用户可收到推送信息
                        </span>
                        <br />
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="按钮名称">
                    <Select v-model="ruleVos[2].ruleValue" style="width: 200px" @on-change="changeRuleVos2">
                        <Option value="立即报名">立即报名</Option>
                        <Option value="立即填报">立即填报</Option>
                        <Option value="立即参与">立即参与</Option>
                        <Option value="立即预约">立即预约</Option>
                        <!-- <Option value="其他">其他</Option> -->
                        <Option value="自定义">自定义</Option>
                    </Select>
                    <span style="color: #aaa; margin-left: 16px">注：用户参与的时候看到的按钮名称</span>
                </FormItem>
                <FormItem v-if="ruleVos[2].ruleValue == '其他' || ruleVos[2].ruleValue == '自定义'">
                    <span slot="label" class="validate">名称:</span>
                    <Input v-model.trim="formData.buttonName" :maxlength="10" placeholder="限10字" style="width: 200px"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">是否推荐:</span>
                    <RadioGroup v-model="formData.recommend">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label">抽奖活动:</span>
                    <RadioGroup v-model="formData.luckDraw">
                        <Radio label="0">否</Radio>
                        <Radio label="1">是</Radio>
                    </RadioGroup>
                </FormItem>

                <FormItem v-if="formData.luckDraw && formData.luckDraw == '1'">
                    <div class="luckDraw-div" style="display: inline-block; width: 460px">
                        <div class="title">
                            <span class="span-div span1">奖品名称</span>
                            <span class="span-div span2">奖品数量</span>
                        </div>
                        <div v-for="(item, index) in formData.prizeVos" :key="index">
                            <Input v-model.trim="item.itemName" :maxlength="10" placeholder="限10字" style="width: 200px"></Input>
                            <InputNumber style="width: 200px; margin-left: 10px" :max="999999999" :min="1" v-model="item.itemNum"></InputNumber>
                            <Button v-if="formData.prizeVos && formData.prizeVos.length >= 2" icon="ios-trash-outline" type="error" style="margin-left: 10px" @click="deletePrizeVos(index)"></Button>
                        </div>
                    </div>
                    <div style="margin-top: 20px">
                        <Button icon="ios-add" type="primary" @click="addPrizeVos()"></Button>
                    </div>
                </FormItem>
            </Form>
        </div>
        <LiefengModal :value="formTemplateStatus" @input="formTemplateStatusFn" :fullscreen="true" title="模板库">
            <template v-slot:contentarea>
                <Tabs type="card" @on-click="toggleTab" class="tempContainer">
                    <TabPane label="全部模板">
                        <div class="tempBox">
                            <span v-if="formTemplateDate.length == 0" class="noList">暂无模板，请先添加</span>
                            <Card v-for="(item, index) in formTemplateDate" :key="index" v-else>
                                <p slot="title">{{ item.formName }}</p>
                                <p>创建：{{ item.createName }}</p>
                                <p>创建时间：{{ item.gmtCreate }}</p>
                                <p>共{{ item.fieldNum }}题</p>
                                <div style="text-align: right">
                                    <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                                    <Button type="success" @click="previewForm(item.id)">预览</Button>
                                </div>
                            </Card>
                        </div>
                        <Page style="float: right; margin-right: 8px" :current="page" :page-size="pageSize" :total="total" show-total @on-change="changePage" />
                    </TabPane>
                    <TabPane label="个人模板">
                        <div class="tempBox">
                            <span v-if="formTemplateDate.length == 0" class="noList"><i>暂无模板，请先添加</i></span>
                            <Card v-for="(item, index) in formTemplateDate" :key="index" v-else>
                                <p slot="title">{{ item.formName }}</p>
                                <p>创建：{{ item.createName }}</p>
                                <p>创建时间：{{ item.gmtCreate }}</p>
                                <p>共{{ item.fieldNum }}题</p>
                                <div style="text-align: right">
                                    <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                                    <Button type="success" @click="previewForm(item.id)">预览</Button>
                                </div>
                            </Card>
                        </div>
                        <Page style="float: right; margin-right: 8px" :current="page" :page-size="pageSize" :total="total" show-total @on-change="changePage" />
                    </TabPane>
                </Tabs>
            </template>
        </LiefengModal>
        <!-- 引用表单预览 -->
        <LiefengModal :title="previeswTitle" :fullscreen="true" :value="previewStatus" @input="previewStatusFn">
            <template v-slot:contentarea>
                <PreviewForm :previewFormData="previewFormData" v-if="previewStatus"></PreviewForm>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="previewStatus = false">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 编辑表单预览 -->
        <LiefengModal title="表单预览" :fullscreen="true" :value="onlyPreviewStatus" @input="onlyPreviewStatusFn">
            <template v-slot:contentarea>
                <PreviewForm :onlyPreview="true" :activeMainList="activeMainList" v-if="onlyPreviewStatus"></PreviewForm>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="onlyPreviewStatus = false">关闭</Button>
            </template>
        </LiefengModal>

        <!-- 图片模板 -->
        <LiefengModal @input="changeImgStatus" title="模板图片" width="868px" height="600px" :value="imgStatus">
            <template v-slot:contentarea>
                <div class="imgbox">
                    <div @click="selectImg(item.filePath)" class="imgitem" v-for="item in imgTemplateList" :key="item.index">
                        <img :src="item.filePath" alt="" />
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 10px" @click="changeImgStatus(false)">取消</Button>
            </template>
        </LiefengModal>
        <!-- 推送选群 -->
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengEditor from "@/components/LiefengEditor"
import AddForm from "@/views/form/childrens/addForm"
import ChangeForm from "@/views/form/childrens/changeForm"
import LiefengModal from "@/components/LiefengModal"
import PreviewForm from "@/views/form/childrens/previewForm"
import Mymodel from "@/views/activity/Mymodel"
import CheckGroup from "./checkgroup.vue"
import proxy from "@/api/proxy"
import Compressor from "compressorjs"
export default {
    props: ["menuCodeObj", "activeId", "addChangeIden", "addType", "formDataObj", "quoteNum", "buttonRoot"],
    data() {
        return {
            pushType: [],
            dimensionId: "",
            groupStatus: false,
            //富文本预览
            toggle1: false, //
            textObj: {
                spinShow: false,
                signInUrl: "", //预览二维码url
            },
            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [100, 69], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            showMenu: "baseInfo",
            formData: {
                repetition: "3",
                whetherSign: "0",
                whetherCrypto: "0",
                recommend: "0", // 是否推荐
                pushChannel: false, //小程序推送通知
                registered_card: "0", // 是否生成排号
                whetherRegistration: "0",
                perCapita: 0,
                terminal: ["1", "3", "4"],
                time2: (() => {
                    const end = new Date()
                    const start = new Date()
                    end.setTime(start.getTime() + 3600 * 1000 * 24)
                    return [start, end]
                })(),
                time: (() => {
                    const end = new Date()
                    const start = new Date()
                    end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                    return [start, end]
                })(),
                source: parent.vue.loginInfo.userinfo.orgName,
                appointmentType: "1",
                prizeVos: [
                    {
                        itemName: "",
                        itemNum: 1,
                    },
                ],
                luckDraw: "0",
            }, //新增修改时提交的数据第一层
            cycleDates: [], //重复内容--日期 / 周
            cycleDateList: (() => {
                let arr = []
                for (let i = 1; i < 32; i++) {
                    arr.push({
                        value: i,
                        label: i,
                    })
                }
                return arr
            })(),
            cycleTimeList: [
                { value: 1, label: "周一" },
                { value: 2, label: "周二" },
                { value: 3, label: "周三" },
                { value: 4, label: "周四" },
                { value: 5, label: "周五" },
                { value: 6, label: "周六" },
                { value: 7, label: "周日" },
            ],
            //开展时间
            cycleTimes: [
                {
                    limits: [
                        {
                            attrType: "person",
                            attrValue: null,
                        },
                    ],
                    timeValue: ["09:00", "11:00"],
                },
            ],
            dateOptionOne: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                ],
            },
            dateOption: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                    {
                        text: "7天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                            return [start, end]
                        },
                    },
                    {
                        text: "30天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
                            return [start, end]
                        },
                    },
                    {
                        text: "365天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
                            return [start, end]
                        },
                    },
                    {
                        text: "五年",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                            return [start, end]
                        },
                    },
                ],
            },
            // 禁用重复开展——按月按周
            disabledRepetition: false,
            disabledNorepetition: false,
            // 发布终端临时变量
            terminalList: [],
            dataScopeIdList: [], //发布范围列表
            prov: [],
            city: [],
            region: [],
            stree: [],
            project: [],
            proList: [],
            cityList: [],
            regionList: [],
            streeList: [],
            projectList: [],

            cityDisabled: true,
            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,
            // 上传图片
            action: "",
            file: "",
            imageName: "",
            imagePath: "",
            uploadData: {},
            ruleVos: [
                //规则
                {
                    ruleCode: "authentication", //实名认证
                    ruleValue: "",
                },
                {
                    ruleCode: 'participate_integral"', //参与获得积分
                    ruleValue: 0,
                },
                {
                    ruleCode: "button_name", //按钮名称
                    ruleValue: "立即填报",
                },
                {
                    ruleCode: "whether_period", //是否需预约具体时段
                    ruleValue: "1",
                },
                {
                    ruleCode: "multiple_period", //是否可选多个时段报名
                    ruleValue: "1",
                },
                {
                    ruleCode: "registered_card", //是否可选多个时段报名
                    ruleValue: "0",
                },
                {
                    ruleCode: "repetition_type",
                    ruleValue: "1",
                },
                {
                    ruleCode: "join_number_limit",
                    ruleValue: "0",
                },
                {
                    ruleCode: "SUCCESS_IMAGE",
                    ruleValue: "",
                },
                {
                    ruleCode: "SUCCESS_DESC",
                    ruleValue: "",
                },
            ],
            // 模板弹窗
            formTemplateStatus: false,
            formTemplateDate: [],
            page: 1,
            pageSize: 9,
            total: 0,
            tabName: 0,
            changeFormShow: false, //控制显示新增还是修改表单组件
            infoId: "",
            formDetailVo: {}, //切换菜单时暂存模板表单数据

            // 预览
            previewStatus: false,
            previewFormData: {},
            previeswTitle: "",

            // 保存校验必填项
            validateArr: [
                {
                    text: "基本信息———标题不能为空",
                    value: "title",
                },
                {
                    text: "基本信息———公布时间不能为空",
                    value: "time",
                },
                {
                    text: "基本信息———请选择发布终端",
                    value: "terminal",
                },
                {
                    text: "基本信息———请选择发布范围",
                    value: "prov",
                },
                {
                    text: "基本信息———请上传封面图片",
                    value: "photo",
                },
                {
                    text: "基本信息———活动详情不能为空",
                    value: "content",
                },
                {
                    text: "活动设置———请选择活动类型",
                    value: "appointmentType",
                },
                {
                    text: "活动设置———请选择开展日期",
                    value: "time2",
                },
                {
                    text: "活动设置———请选择是否需预约具体时段",
                    value: "whether_period",
                },
                {
                    text: "活动设置———请选择重复开展",
                    value: "repetition",
                },
                {
                    text: "活动设置———请选择重复内容",
                    value: "cycleDates",
                },
                {
                    text: "活动设置———请设置开展时段",
                    value: "cycleTimes",
                },
                {
                    text: "活动设置———请设置是否可选多个时段报名",
                    value: "multiple_period",
                },
                {
                    text: "活动设置———请选择是否可代报名",
                    value: "whetherRegistration",
                },
                {
                    text: "活动设置———请选择代报填写信息",
                    value: "registrationType",
                },
                {
                    text: "活动设置———请选择是否需签到",
                    value: "whetherSign",
                },
                {
                    text: "活动设置———请选择是否党员活动",
                    value: "whetherCrypto",
                },
                {
                    text: "活动设置———请选择是否推荐",
                    value: "recommend",
                },
                {
                    text: "活动设置———请选择是否生成排号",
                    value: "registered_card",
                },
                {
                    text: "信息来源———请填写信息来源",
                    value: "source",
                },
            ],
            // 修改必传id
            appointmentId: "",
            disabledStatus: false, //控制修改的时候，重复开展等是否禁用

            // 表单编辑时预览
            activeMainList: [],
            onlyPreviewStatus: false,

            activeType: "0",

            // 图片模板
            imgStatus: false,
            imgTemplateList: [],

            editDisabled: false,
        }
    },
    methods: {
        // 删除抽奖
        deletePrizeVos(index) {
            this.formData.prizeVos.splice(index, 1)
        },
        // 新增抽奖
        addPrizeVos() {
            this.formData.prizeVos.push({
                itemName: "",
                itemNum: 1,
            })
        },
        // 修改活动类型触发
        changeApponintType(val) {
            if (val == 1) {
                this.formData.time2 = [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24)]
                this.formData.repetition = "3"
            } else {
                if (val == 3) {
                    this.formData.repetition = "2"
                }
                if (val == 4) {
                    this.formData.repetition = "2"
                    this.ruleVos[5].ruleValue = "1"
                }
                this.formData.time2 = [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)]
            }
        },
        changeGroupStatus(val) {
            this.groupStatus = val
        },
        cancelGroup() {
            this.groupStatus = false
        },
        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            if (list.length == 0) {
                this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
                return
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.formData.groupNames = groupName.join(",")
            this.formData.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data && data.length) {
                this.pushType = []
                this.pushType.push(data[data.length - 1])
                // this.formData.pushType = [...data[1]]
            }
        },
        toggleFn2() {
            this.toggle1 = false
        },
        // 表单编辑时预览
        openOnlyPreview() {
            this.activeMainList = JSON.parse(JSON.stringify(this.$refs.addChangeForm.mainList))
            this.onlyPreviewStatus = true
        },
        onlyPreviewStatusFn(status) {
            this.onlyPreviewStatus = status
        },
        loadUeditor() {
            if (this.formData.content != "" && this.formData.content != null && this.formData.content != undefined) {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                v_Obj.setContent(this.formData.content) //写入编辑器富文本内容
            } else {
                setTimeout(this.loadUeditor, 300)
            }
        },
        // 开展日期改变校验
        changeTime2(time, name) {
            if (this.formData.appointmentType == "1" && name && name != "") {
                this.formData.time2 = [time, this.$core.formatDate(new Date(new Date(time).getTime() + 3600 * 1000 * 24), "yyyy-MM-dd")]
            } else {
                this.formData.time2 = [new Date(time[0]), new Date(time[1])]
            }

            // if (this.formData.appointmentType == "1") {

            // }
            if (time && time[0] && time[1] && time[0] == time[1]) {
                // this.formData.repetition = "0"
                this.disabledRepetition = true
                this.cycleDates = []
            } else {
                // this.formData.repetition = "2"
                this.disabledRepetition = false
                this.disabledNorepetition = true
            }
        },
        // 保存校验
        validateFn() {
            if (this.formData.appointmentType == "2") {
                // 如果是核酸活动
                for (let k in this.validateArr) {
                    if (this.validateArr[k].value == "title" && (!this.formData.title || this.formData.title == "")) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if ((this.validateArr[k].value == "time" || this.validateArr[k].value == "time2") && typeof this.formData[this.validateArr[k].value][0] == "string") {
                        console.log(typeof this.formData[this.validateArr[k].value][0])
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "terminal" && this.formData.terminal.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "prov" && this[this.validateArr[k].value].length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }

                    if (this.validateArr[k].value == "photo" && (!this.formData.photo || this.formData.photo == "")) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "content" && (!this.formData.content || this.formData.content == "")) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }

                    if (this.validateArr[k].value == "registrationType" && this.formData.registrationType == "" && this.formData.whetherRegistration == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }

                    if (this.validateArr[k].value == "appointmentType" && (!this.formData.appointmentType || this.formData.appointmentType == "")) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                }
                return true
            } else if (this.formData.appointmentType == "1") {
                // 如果是一次性活动
                for (let k in this.validateArr) {
                    if (k == this.validateArr.length - 1 && this.formData[this.validateArr[k].value] !== "" && this.formData[this.validateArr[k].value] != undefined) {
                        return true
                    }
                    if (
                        this.validateArr[k].value != "time" &&
                        this.validateArr[k].value != "terminal" &&
                        this.validateArr[k].value != "prov" &&
                        this.validateArr[k].value != "time2" &&
                        this.validateArr[k].value != "whether_period" &&
                        this.validateArr[k].value != "repetition" &&
                        this.validateArr[k].value != "cycleDates" &&
                        this.validateArr[k].value != "cycleTimes" &&
                        this.validateArr[k].value != "multiple_period" &&
                        this.validateArr[k].value != "registrationType" &&
                        this.validateArr[k].value != "registered_card" &&
                        this.validateArr[k].value != "appointmentType" &&
                        (this.formData[this.validateArr[k].value] == "" || this.formData[this.validateArr[k].value] == undefined)
                    ) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (
                        (this.validateArr[k].value == "time" || this.validateArr[k].value == "time2") &&
                        typeof this.formData[this.validateArr[k].value][0] != "string" &&
                        typeof this.formData[this.validateArr[k].value][0] != "object"
                    ) {
                        console.log(typeof this.formData[this.validateArr[k].value][0])
                        console.log(this.formData.time2)
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "terminal" && this.formData.terminal.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "prov" && this[this.validateArr[k].value].length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "whether_period" && !this.ruleVos[3].ruleValue) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "repetition" && !this.formData.repetition && this.ruleVos[3].ruleValue == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "cycleTimes" && this.cycleTimes[0].timeValue[0] == "" && this.ruleVos[3].ruleValue == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "multiple_period" && !this.ruleVos[4].ruleValue && this.ruleVos[3].ruleValue == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "registrationType" && this.formData.registrationType == "" && this.formData.whetherRegistration == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "appointmentType" && this.formData.appointmentType == "") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                }
            } else if (this.formData.appointmentType == "3") {
                // 周期性活动
                for (let k in this.validateArr) {
                    if (k == this.validateArr.length - 1 && this.formData[this.validateArr[k].value] !== "" && this.formData[this.validateArr[k].value] != undefined) {
                        return true
                    }
                    if (
                        this.validateArr[k].value != "time" &&
                        this.validateArr[k].value != "terminal" &&
                        this.validateArr[k].value != "prov" &&
                        this.validateArr[k].value != "time2" &&
                        this.validateArr[k].value != "whether_period" &&
                        this.validateArr[k].value != "repetition" &&
                        this.validateArr[k].value != "cycleDates" &&
                        this.validateArr[k].value != "cycleTimes" &&
                        this.validateArr[k].value != "multiple_period" &&
                        this.validateArr[k].value != "registrationType" &&
                        this.validateArr[k].value != "registered_card" &&
                        this.validateArr[k].value != "appointmentType" &&
                        (this.formData[this.validateArr[k].value] == "" || this.formData[this.validateArr[k].value] == undefined)
                    ) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (
                        (this.validateArr[k].value == "time" || this.validateArr[k].value == "time2") &&
                        typeof this.formData[this.validateArr[k].value][0] == "string" &&
                        typeof this.formData[this.validateArr[k].value][0] == "object"
                    ) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "terminal" && this.formData.terminal.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "prov" && this[this.validateArr[k].value].length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "whether_period" && !this.ruleVos[3].ruleValue) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "repetition" && !this.formData.repetition) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }

                    if (this.validateArr[k].value == "cycleDates" && this.formData.repetition != "0" && this.cycleDates.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "cycleTimes" && this.cycleTimes[0].timeValue[0] == "") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "multiple_period" && !this.ruleVos[4].ruleValue) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "registrationType" && this.formData.registrationType == "" && this.formData.whetherRegistration == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "appointmentType" && this.formData.appointmentType == "") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                }
            } else if (this.formData.appointmentType == "4") {
                // 排号
                for (let k in this.validateArr) {
                    if (k == this.validateArr.length - 1 && this.formData[this.validateArr[k].value] !== "" && this.formData[this.validateArr[k].value] != undefined) {
                        return true
                    }
                    if (
                        this.validateArr[k].value != "time" &&
                        this.validateArr[k].value != "terminal" &&
                        this.validateArr[k].value != "prov" &&
                        this.validateArr[k].value != "time2" &&
                        this.validateArr[k].value != "whether_period" &&
                        this.validateArr[k].value != "repetition" &&
                        this.validateArr[k].value != "cycleDates" &&
                        this.validateArr[k].value != "cycleTimes" &&
                        this.validateArr[k].value != "multiple_period" &&
                        this.validateArr[k].value != "registrationType" &&
                        this.validateArr[k].value != "registered_card" &&
                        this.validateArr[k].value != "appointmentType" &&
                        (this.formData[this.validateArr[k].value] == "" || this.formData[this.validateArr[k].value] == undefined)
                    ) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (
                        (this.validateArr[k].value == "time" || this.validateArr[k].value == "time2") &&
                        typeof this.formData[this.validateArr[k].value][0] != "string" &&
                        typeof this.formData[this.validateArr[k].value][0] != "object"
                    ) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "terminal" && this.formData.terminal.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "prov" && this[this.validateArr[k].value].length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "whether_period" && !this.ruleVos[3].ruleValue) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "repetition" && !this.formData.repetition) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "cycleDates" && this.formData.repetition != "0" && this.cycleDates.length == 0) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "cycleTimes" && this.cycleTimes[0].timeValue[0] == "") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "multiple_period" && !this.ruleVos[4].ruleValue) {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "registrationType" && this.formData.registrationType == "" && this.formData.whetherRegistration == "1") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                    if (this.validateArr[k].value == "appointmentType" && this.formData.appointmentType == "") {
                        this.$Message["error"]({
                            background: true,
                            content: this.validateArr[k].text,
                        })
                        return false
                    }
                }
            }
        },

        checkDraw() {
            if (this.formData.luckDraw && this.formData.luckDraw == "1") {
                if (!this.formData.prizeVos || !this.formData.prizeVos.length) {
                    this.$Message.error({
                        content: "更多设置-请设置抽奖活动奖品名称及奖品数量",
                        background: true,
                    })
                    return false
                } else {
                    var isTure = false
                    this.formData.prizeVos.map(item => {
                        if (item.itemName == "" || item.itemNum == "") isTure = true
                    })
                    if (isTure) {
                        this.$Message.error({
                            content: "更多设置-请设置抽奖活动奖品名称及奖品数量",
                            background: true,
                        })
                        return false
                    }
                }
                return true
            }
            return true
        },

        contentFn() {
            //富文本预览
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.formData.content = v_Obj.getContent() //获取富文本内容

            if (!this.formData.content) {
                this.$Message.warning({
                    background: true,
                    content: "暂没内容，点击编辑吧！",
                    duration: 2,
                })
                return
            }
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })

            let params = {
                content: this.formData.content,
            }
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    type: "RICH_TEXT_PREVIEW_PAGE",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/indexPages/previewRichText/index",
                        params: {
                            ...params,
                        },
                    }),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200 && res.data) {
                    this.textObj.signInUrl = res.data.codeUrl
                    this.toggle1 = true
                    this.$Message.destroy()
                } else {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        async save() {
            try {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                this.formData.content = v_Obj.getContent() //获取富文本内容
                // 校验必填项

                if (!this.validateFn()) {
                    this.$emit("failUp")
                    return
                }
                if (!this.checkDraw()) {
                    this.$emit("failUp")
                    return
                }

                if (this.formData.luckDraw && this.formData.luckDraw == "0") this.formData.prizeVos = []
                // 校验开展时段是否重复
                if (this.ruleVos[3].ruleValue == '1' && this.cycleTimes) {
                    for (let k in this.cycleTimes) {
                        // 校验每个开展时段的开始和结束重复
                        if (this.cycleTimes[k].timeValue[0] == this.cycleTimes[k].timeValue[1]) {
                            this.$Message.error({
                                background: true,
                                content: `活动设置———第${Number(k) + 1}个开展时段的开始时间与结束时间不能相同`,
                            })
                            this.$emit("failUp")
                            return
                        }

                        // 校验开展时段间重复
                        let value = this.cycleTimes[k].timeValue[0] + "-" + this.cycleTimes[k].timeValue[1]
                        for (let i in this.cycleTimes) {
                            let value2 = this.cycleTimes[i].timeValue[0] + "-" + this.cycleTimes[i].timeValue[1]
                            if (k != i && value == value2) {
                                this.$Message.error({
                                    background: true,
                                    content: `活动设置———第${Number(k) + 1}个与第${Number(i) + 1}个开展时段重复`,
                                })
                                this.$emit("failUp")
                                return
                            }
                        }
                    }
                }
                if (this.formData.selectForm) {
                    this.formDetailVo = this.$refs.addChangeForm.exportFormData()
                    if (this.formDetailVo.interactionFormVos.length == 0) {
                        this.$Message.error({
                            background: true,
                            content: "请设置表单",
                        })
                        this.$emit("failUp")
                        return
                    }
                }

                if (this.ruleVos[0]) {
                    if (this.ruleVos[0].ruleValue == "") {
                        this.ruleVos[0].ruleValue = ""
                    }
                    if (this.ruleVos[0].ruleValue == "1") {
                        this.ruleVos[0].ruleValue = "true"
                    }
                    if (this.ruleVos[0].ruleValue == "2") {
                        this.ruleVos[0].ruleValue = "false"
                    }
                }

                this.$Message.loading({
                    content: "正在保存数据，请稍等...",
                    duration: 0,
                })
                let url = ""
                let params = {}
                if (this.formData.pushType && this.formData.pushType.length != 0) this.formData.pushType = this.formData.pushType[0]
                else this.formData.pushType = ""

                if (this.addChangeIden) {
                    //新增
                    url = "/info/api/pc/information/appointment/create"
                    if (this.formData.appointmentType == "1" || (this.ruleVos[3].ruleValue == "1" && this.formData.appointmentType != "2")) {
                        params = {
                            createCycleVo: {
                                cycleType: this.formData.appointmentType == "1" ? "0" : this.formData.repetition,

                                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                                repeatStatus: this.formData.repetition == "0" ? "2" : "1",
                                status: "1",
                                creator: parent.vue.loginInfo.userinfo.realName,
                                creatorId: parent.vue.loginInfo.userinfo.id,
                                cycleDates:
                                    this.formData.appointmentType == "1" && this.ruleVos[3].ruleValue == "0"
                                        ? []
                                        : (() => {
                                              if (this.cycleDates.length > 0) {
                                                  let result = this.cycleDates.map(item => {
                                                      return {
                                                          dateType: this.formData.repetition,
                                                          dateValue: item,
                                                      }
                                                  })
                                                  return result
                                              } else {
                                                  //不重复，取开展日期
                                                  return [
                                                      {
                                                          dateType: this.formData.repetition,
                                                          dateValue: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                                                      },
                                                  ]
                                              }
                                          })(),

                                cycleTimes:
                                    this.formData.appointmentType == "1" && this.ruleVos[3].ruleValue == "0"
                                        ? []
                                        : (() => {
                                              let result = this.cycleTimes.map(item => {
                                                  return {
                                                      limits: [
                                                          {
                                                              attrType: "person",
                                                              attrValue: item.limits[0].attrValue ? item.limits[0].attrValue : "n",
                                                          },
                                                      ],
                                                      timeValue: item.timeValue[0] + "-" + item.timeValue[1],
                                                  }
                                              })
                                              return result
                                          })(),
                            },
                        }
                    }
                } else {
                    url = "/info/api/pc/information/appointment/update"
                    if (this.ruleVos[3].ruleValue == "1" && this.formData.appointmentType != "2") {
                        params = {
                            id: this.activeId,
                            appointmentId: this.appointmentId,
                            updateCycleVo: {
                                change: this.formData.repetition == this.formData.newRepetition ? null : true, //辨别是否改了周期
                                creator: parent.vue.loginInfo.userinfo.realName,
                                creatorId: parent.vue.loginInfo.userinfo.id,
                                cycleDates: (() => {
                                    if (this.cycleDates.length > 0) {
                                        let result = this.cycleDates.map(item => {
                                            return {
                                                dateType: this.formData.repetition,
                                                dateValue: item,
                                            }
                                        })
                                        return result
                                    } else {
                                        //不重复，取开展日期
                                        return [
                                            {
                                                dateType: this.formData.repetition,
                                                dateValue: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                                            },
                                        ]
                                    }
                                })(),

                                cycleTimes: (() => {
                                    let result = this.cycleTimes.map(item => {
                                        return {
                                            limits: [
                                                {
                                                    attrType: "person",
                                                    attrValue: item.limits[0].attrValue,
                                                },
                                            ],
                                            timeValue: item.timeValue[0] + "-" + item.timeValue[1],
                                        }
                                    })
                                    return result
                                })(),
                            },
                        }
                    } else {
                        params = {
                            id: this.activeId,
                            change: this.formData.repetition == this.formData.newRepetition ? null : true, //辨别是否改了周期
                            appointmentId: this.appointmentId,
                        }
                    }
                }

                if (this.formData.selectForm) {
                    params.formDetailVo = this.formDetailVo
                }

                // zhw start

                if (Object.keys(this.formDetailVo).length != 0 && this.formDetailVo.interactionFormVos.length != 0) {
                    this.formDetailVo.interactionFormVos.map(item => {
                        if (item.fieldParamVos.length != 0) {
                            let obj = {}
                            let isTrue = false
                            item.fieldParamVos.map(items => {
                                if (items.paramCode != "fieldType") {
                                    switch (item.formType) {
                                        case "userName":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "姓名", value: "userName" }
                                            isTrue = true
                                            break
                                        case "mobile":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "手机号", value: "mobile" }
                                            isTrue = true
                                            break
                                        case "idNum":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "身份证号", value: "idNum" }
                                            isTrue = true
                                            break
                                        case "addr":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "地址", value: "addr" }
                                            isTrue = true
                                            break
                                        case "sex":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "性别", value: "sex" }
                                            isTrue = true
                                            break
                                        case "age":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "年龄", value: "age" }
                                            isTrue = true
                                            break
                                        case "marital":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "婚姻状况", value: "marital" }
                                            isTrue = true
                                            break
                                        case "workUnits":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "工作单位", value: "workUnits" }
                                            isTrue = true
                                            break
                                        case "national":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "民族", value: "national" }
                                            isTrue = true
                                            break
                                        case "brithday":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "出生日期", value: "brithday" }
                                            isTrue = true
                                            break
                                        case "political":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "政治面貌", value: "political" }
                                            isTrue = true
                                            break
                                        case "vaccines":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "新冠疫苗接种情况", value: "vaccines" }
                                            isTrue = true
                                            break
                                        case "brand":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "接种品牌", value: "brand" }
                                            isTrue = true
                                            break
                                        case "testing":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检测时间", value: "testing" }
                                            isTrue = true
                                            break
                                        case "result":
                                            obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检查结果", value: "result" }
                                            isTrue = true
                                            break
                                    }
                                }
                            })

                            if (isTrue) {
                                item.fieldParamVos.push(obj)
                            }
                        }
                    })
                }

                let postFormData = {}
                //   return
                if (this.formData.appointmentType == "1") {
                    if (this.ruleVos[3].ruleValue == "0") delete params.createCycleVo
                    // 一次性活动
                    postFormData = {
                        ...this.formData,
                        pushChannel: this.formData.pushChannel ? "4" : "",
                        ...params,
                        repetition: this.ruleVos[3].ruleValue == "0" ? "3" : "0",
                        cycleType: "0",
                        venueTime: this.formData.venueTime ? this.$core.formatDate(new Date(this.formData.venueTime), "yyyy-MM-dd hh:mm:ss") : "",
                        startTime: this.$core.formatDate(new Date(this.formData.time[0]), "yyyy-MM-dd hh:mm:ss"),
                        endTime: this.$core.formatDate(new Date(this.formData.time[1]), "yyyy-MM-dd hh:mm:ss"),
                        terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        dataScopeIdList: (() => {
                            if (this.project.length > 0) {
                                return (this.project = typeof this.project == "string" ? [this.project] : this.project)
                            } else if (this.stree.length > 0) {
                                return (this.stree = typeof this.stree == "string" ? [this.stree] : this.stree)
                            } else if (this.region.length > 0) {
                                return (this.region = typeof this.region == "string" ? [this.region] : this.region)
                            } else if (this.city.length > 0) {
                                return (this.city = typeof this.city == "string" ? [this.city] : this.city)
                            } else {
                                return typeof this.prov == "string" ? this.prov.split(",") : this.prov
                            }
                        })(),
                        startDate: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                        endDate: this.$core.formatDate(new Date(this.formData.time2[1]), "yyyy-MM-dd"),
                        status: "1",
                        // recommend: "0",
                        sort: "100",
                        time: null,
                        time2: null,
                        columnCode: this.menuCodeObj.columnCode,

                        ruleVos: [
                            {
                                ruleCode: "authentication",
                                ruleValue: this.ruleVos[0].ruleValue,
                            },
                            {
                                ruleCode: "participate_integral",
                                ruleValue: this.ruleVos[1].ruleValue,
                            },
                            {
                                ruleCode: "button_name",
                                ruleValue: this.ruleVos[2].ruleValue == "其他" || this.ruleVos[2].ruleValue == "自定义" ? this.formData.buttonName : this.ruleVos[2].ruleValue,
                            },
                            {
                                ruleCode: "whether_period",
                                ruleValue: this.ruleVos[3].ruleValue,
                            },
                            {
                                ruleCode: "multiple_period",
                                ruleValue: this.ruleVos[3].ruleValue == "1" ? this.ruleVos[4].ruleValue : "0",
                            },
                            {
                                // 生成排号
                                ruleCode: "registered_card",
                                ruleValue: "0",
                            },
                            {
                                ruleCode: "join_number_limit",
                                ruleValue: this.ruleVos[3].ruleValue == "0" ? (this.ruleVos[7].ruleValue == "0" ? "n" : this.ruleVos[7].ruleValue) : "n",
                            },
                        ],
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        operator: {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        },
                    }
                } else if (this.formData.appointmentType == "2") {
                    // 一键登记
                    postFormData = {
                        // 类型2
                        ...this.formData,
                        repetition: "3",
                        ...params,
                        pushChannel: this.formData.pushChannel ? "4" : "",
                        cycleTimes: [],
                        perCapita: "",
                        addr: "",
                        range: "",
                        venueTime: "",
                        cycleType: "3",
                        startTime: this.$core.formatDate(new Date(this.formData.time[0]), "yyyy-MM-dd hh:mm:ss"),
                        endTime: this.$core.formatDate(new Date(this.formData.time[1]), "yyyy-MM-dd hh:mm:ss"),
                        terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        dataScopeIdList: (() => {
                            if (this.project.length > 0) {
                                return (this.project = typeof this.project == "string" ? [this.project] : this.project)
                            } else if (this.stree.length > 0) {
                                return (this.stree = typeof this.stree == "string" ? [this.stree] : this.stree)
                            } else if (this.region.length > 0) {
                                return (this.region = typeof this.region == "string" ? [this.region] : this.region)
                            } else if (this.city.length > 0) {
                                return (this.city = typeof this.city == "string" ? [this.city] : this.city)
                            } else {
                                return typeof this.prov == "string" ? this.prov.split(",") : this.prov
                            }
                        })(),
                        startDate: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                        endDate: this.$core.formatDate(new Date(this.formData.time2[1]), "yyyy-MM-dd"),
                        status: "1",
                        // recommend: "0",
                        sort: "100",
                        time: null,
                        time2: null,
                        columnCode: this.menuCodeObj.columnCode,

                        ruleVos: [
                            {
                                ruleCode: "authentication",
                                ruleValue: this.ruleVos[0].ruleValue,
                            },
                            {
                                ruleCode: "participate_integral",
                                ruleValue: this.ruleVos[1].ruleValue,
                            },
                            {
                                ruleCode: "button_name",
                                ruleValue: this.ruleVos[2].ruleValue == "其他" || this.ruleVos[2].ruleValue == "自定义" ? this.formData.buttonName : this.ruleVos[2].ruleValue,
                            },
                            {
                                ruleCode: "whether_period",
                                ruleValue: "0",
                            },
                            {
                                ruleCode: "multiple_period",
                                ruleValue: "0",
                            },
                            {
                                ruleCode: "registered_card",
                                ruleValue: "0",
                            },
                            {
                                ruleCode: "epidemic_online_home",
                                ruleValue: this.formData.appointmentType && this.formData.appointmentType == "2" ? "1" : "0",
                            },
                            {
                                ruleCode: "repetition_type",
                                ruleValue: this.ruleVos[6].ruleValue,
                            },
                        ],
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        operator: {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        },
                    }
                } else if (this.formData.appointmentType == "3") {
                    // 周期性活动
                    postFormData = {
                        ...this.formData,
                        pushChannel: this.formData.pushChannel ? "4" : "",
                        repetition: this.ruleVos[3].ruleValue == "1" ? this.formData.repetition : "3",
                        cycleType: this.ruleVos[3].ruleValue == "1" ? this.formData.repetition : "3",
                        ...params,
                        venueTime: this.formData.venueTime ? this.$core.formatDate(new Date(this.formData.venueTime), "yyyy-MM-dd hh:mm:ss") : "",
                        startTime: this.$core.formatDate(new Date(this.formData.time[0]), "yyyy-MM-dd hh:mm:ss"),
                        endTime: this.$core.formatDate(new Date(this.formData.time[1]), "yyyy-MM-dd hh:mm:ss"),
                        terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        dataScopeIdList: (() => {
                            if (this.project.length > 0) {
                                return (this.project = typeof this.project == "string" ? [this.project] : this.project)
                            } else if (this.stree.length > 0) {
                                return (this.stree = typeof this.stree == "string" ? [this.stree] : this.stree)
                            } else if (this.region.length > 0) {
                                return (this.region = typeof this.region == "string" ? [this.region] : this.region)
                            } else if (this.city.length > 0) {
                                return (this.city = typeof this.city == "string" ? [this.city] : this.city)
                            } else {
                                return typeof this.prov == "string" ? this.prov.split(",") : this.prov
                            }
                        })(),
                        startDate: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                        endDate: this.$core.formatDate(new Date(this.formData.time2[1]), "yyyy-MM-dd"),
                        status: "1",
                        // recommend: "0",
                        sort: "100",
                        time: null,
                        time2: null,
                        columnCode: this.menuCodeObj.columnCode,

                        ruleVos: [
                            {
                                ruleCode: "authentication",
                                ruleValue: this.ruleVos[0].ruleValue,
                            },
                            {
                                ruleCode: "participate_integral",
                                ruleValue: this.ruleVos[1].ruleValue,
                            },
                            {
                                ruleCode: "button_name",
                                ruleValue: this.ruleVos[2].ruleValue == "其他" || this.ruleVos[2].ruleValue == "自定义" ? this.formData.buttonName : this.ruleVos[2].ruleValue,
                            },
                            {
                                ruleCode: "whether_period",
                                ruleValue: "1",
                            },
                            {
                                ruleCode: "multiple_period",
                                ruleValue: this.ruleVos[4].ruleValue,
                            },
                            {
                                ruleCode: "registered_card",
                                ruleValue: "0",
                            },
                        ],
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        operator: {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        },
                    }
                } else if (this.formData.appointmentType == "4") {
                    // 排号
                    // 周期性活动
                    postFormData = {
                        ...this.formData,
                        whetherSign: 0, // 需签到
                        whetherCrypto: 0, // 党员互动
                        pushChannel: this.formData.pushChannel ? "4" : "",
                        repetition: this.ruleVos[3].ruleValue == "1" ? this.formData.repetition : "3",
                        cycleType: this.ruleVos[3].ruleValue == "1" ? this.formData.repetition : "3",
                        ...params,
                        venueTime: this.formData.venueTime ? this.$core.formatDate(new Date(this.formData.venueTime), "yyyy-MM-dd hh:mm:ss") : "",
                        startTime: this.$core.formatDate(new Date(this.formData.time[0]), "yyyy-MM-dd hh:mm:ss"),
                        endTime: this.$core.formatDate(new Date(this.formData.time[1]), "yyyy-MM-dd hh:mm:ss"),
                        terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        dataScopeIdList: (() => {
                            if (this.project.length > 0) {
                                return (this.project = typeof this.project == "string" ? [this.project] : this.project)
                            } else if (this.stree.length > 0) {
                                return (this.stree = typeof this.stree == "string" ? [this.stree] : this.stree)
                            } else if (this.region.length > 0) {
                                return (this.region = typeof this.region == "string" ? [this.region] : this.region)
                            } else if (this.city.length > 0) {
                                return (this.city = typeof this.city == "string" ? [this.city] : this.city)
                            } else {
                                return typeof this.prov == "string" ? this.prov.split(",") : this.prov
                            }
                        })(),
                        startDate: this.$core.formatDate(new Date(this.formData.time2[0]), "yyyy-MM-dd"),
                        endDate: this.$core.formatDate(new Date(this.formData.time2[1]), "yyyy-MM-dd"),
                        status: "1",
                        // recommend: "0",
                        sort: "100",
                        time: null,
                        time2: null,
                        columnCode: this.menuCodeObj.columnCode,

                        ruleVos: [
                            {
                                ruleCode: "authentication",
                                ruleValue: this.ruleVos[0].ruleValue,
                            },
                            {
                                ruleCode: "participate_integral",
                                ruleValue: this.ruleVos[1].ruleValue,
                            },
                            {
                                ruleCode: "button_name",
                                ruleValue: this.ruleVos[2].ruleValue == "其他" || this.ruleVos[2].ruleValue == "自定义" ? this.formData.buttonName : this.ruleVos[2].ruleValue,
                            },
                            {
                                ruleCode: "whether_period",
                                ruleValue: "1",
                            },
                            {
                                ruleCode: "multiple_period",
                                ruleValue: this.ruleVos[4].ruleValue,
                            },
                            {
                                ruleCode: "registered_card",
                                ruleValue: "1",
                            },
                        ],
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        operator: {
                            businessType: this.menuCodeObj.businessType,
                            functionType: this.menuCodeObj.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            terminal: this.formData.terminal.indexOf("0") != -1 ? "0" : this.formData.terminal.join(","),
                        },
                    }
                }

                if (this.addChangeIden) {
                    if (this.formDetailVo && this.formDetailVo.id) {
                        this.changeFromItemId()
                    }
                } else {
                    this.ruleVos.map(item => {
                        if (item.ruleCode == "SUCCESS_IMAGE" || item.ruleCode == "SUCCESS_DESC") {
                            postFormData.ruleVos.push({
                                ...item,
                            })
                        }
                    })

                    console.log("如果是修改并且数组含有两个元素", this.ruleVos, postFormData)
                }
                // zhw end
                await this.$post(url, postFormData, { "Content-Type": "application/json" })
                    .then(res => {
                        this.$Message.destroy()
                        if (res.code == 200) {
                            this.$Message.success({
                                background: true,
                                content: "保存成功",
                            })
                            this.$emit("saveSuccess")
                        } else {
                            this.$Message.error({
                                background: true,
                                content: res.desc,
                            })
                            this.$emit("failUp")
                            return
                        }
                    })
                    .catch(err => {
                        if (this.ruleVos[0].ruleValue) {
                            if (this.ruleVos[0].ruleValue == "true") {
                                this.ruleVos[0].ruleValue = "1"
                            }
                            if (this.ruleVos[0].ruleValue == "false") {
                                this.ruleVos[0].ruleValue = "2"
                            }
                            if (this.ruleVos[0].ruleValue == "") {
                                this.ruleVos[0].ruleValue = ""
                            }
                        }
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: "保存失败，请重试",
                        })
                        this.$emit("failUp")
                    })
            } catch (err) {
                console.log("err", err)
                this.$emit("failUp")
                return
            }
        },
        // 模板库
        formTemplateStatusFn(status) {
            this.formTemplateStatus = status
        },
        changePage(size) {
            this.handleTableData({
                page: size,
                pageSize: this.pageSize,
            })
        },
        // 引用
        recommend(id) {
            this.$Message.loading({
                content: "正在加载模板数据，请稍等...",
                duration: 0,
            })
            this.infoId = ""
            this.$get("/datamsg/api/pc/dynamicform/copyFormDetail", {
                formId: id,
            })
                .then(res => {
                    if (res.code == 200 && res.data) {
                        this.formDetailVo = res.data
                        this.changeFormShow = ""
                        this.$nextTick(() => {
                            this.changeFormShow = true
                        })
                        this.$get("/datamsg/api/pc/dynamicform/citations", {
                            formId: id,
                        })
                        this.$Message.destroy()
                        this.formTemplateStatus = false
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 预览
        previewStatusFn(status) {
            this.previewStatus = status
        },
        previewForm(id) {
            this.$Message.loading("正在加载数据,请稍等...")
            this.$get("/datamsg/api/pc/dynamicform/selectFormDetail", {
                formId: id,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200 && res.data) {
                    this.previewFormData = res.data
                    this.previeswTitle = res.data.formTitle
                    this.previewStatus = true
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // tab切换
        toggleTab(number) {
            this.tabName = number
            this.handleTableData({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        handleTableData(obj) {
            this.$get("/datamsg/api/pc/dynamicform/selectFormPage", {
                orgCode: this.tabName == 0 ? "" : parent.vue.loginInfo.userinfo.orgCode,
                page: obj.page,
                pageSize: obj.pageSize,
                custGlobalId: this.tabName == 0 ? "" : parent.vue.loginInfo.userinfo.custGlobalId,
                templateType: this.tabName == 0 ? "2" : "1", //1：私有；2：公开
            })
                .then(res => {
                    this.$Message.destroy()
                    if (res.code == 200 && res.dataList) {
                        res.dataList.map(item => {
                            item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd hh:mm:ss")
                        })
                        this.formTemplateDate = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                        if (!this.formTemplateStatus) this.formTemplateStatus = true //代表打开弹窗
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        openFormTemp(obj) {
            this.$Message.loading({
                content: "正在加载模板数据，请稍等...",
                duration: 0,
            })
            this.handleTableData({
                page: this.page,
                pageSize: this.pageSize,
            })
        },
        // 切换菜单
        selectMenu(name) {
            this.showMenu = name
        },
        // 发布终端全选
        allSelect(arr) {
            if (this.terminalList.length == 6 && arr.indexOf("0") == -1) {
                this.formData.terminal = []
            } else if (this.terminalList.length == 6 && arr.indexOf("0") > -1) {
                arr.splice(arr.indexOf("0"), 1)
                this.formData.terminal = arr
            } else if (arr.length == 5 && arr.indexOf("0") == -1) {
                this.formData.terminal = ["0", "1", "2", "3", "4", "5"]
            } else if (arr.indexOf("0") > -1 && arr.length < 6) {
                this.formData.terminal = ["0", "1", "2", "3", "4", "5"]
            }
            this.terminalList = JSON.parse(JSON.stringify(this.formData.terminal))
        },
        // 添加开展时段
        addCycleTimes() {
            this.cycleTimes.push({
                limits: [
                    {
                        attrType: "person",
                        attrValue: null,
                    },
                ],
                timeValue: [],
            })
        },
        // 删除开展时段
        delCycleTimes() {
            if (this.cycleTimes.length == 1) {
                this.$Message.error({
                    background: true,
                    content: "删除失败：请至少一个开展时段",
                })
                return
            }
            this.cycleTimes.pop()
        },
        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },

        //图片裁剪
        async cropperFn() {
            if (!this.uploadData.host) {
                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.formData.photo.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action = proxy["/oss"].target
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }

            let _this = this
            this.setAvatarBase64(this.formData.photo, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function () {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.formData.photo = ""
                    this.$nextTick(() => {
                        this.formData.photo = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },
        // 上传之前
        async beforeUpload(file) {
            if (file.type == "image/gif") {
                const uploadData = await this.$core.uplaodImage(file)
                this.uploadData = uploadData

                this.imageName = this.uploadData.name
                this.imagePath = this.uploadData.host + this.uploadData.key
            } else {
                return new Promise((resolve, reject) => {
                    new Compressor(file, {
                        quality: 0.5, // 压缩质量，0.6 表示压缩到原图的 60%
                        convertSize: 200, // 需要压缩的起始大小，默认5M, 5 * 1000 * 1000
                        success: async result => {
                            console.log("压缩成功之后", result)
                            var res = new File([result], result.name, {
                                type: result.type,
                                lastModified: Date.now(),
                            })
                            const uploadData = await this.$core.uplaodImage(res)
                            this.uploadData = uploadData

                            this.imageName = this.uploadData.name
                            this.imagePath = this.uploadData.host + this.uploadData.key

                            console.log(this.uploadData, this.imageName, this.imagePath)
                            resolve(res)
                        },
                        error(err) {
                            reject(err)
                        },
                    })
                })
            }
            console.log("file", file.type)
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.formData.photo = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.formData.photo = ""
            this.file = {}
        },
        // 省的选择
        // 城市选择
        changeProv(code) {
            if (code.length >= 2) {
                this.cityList = []
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.city = []
                this.region = []
                this.stree = []
                this.project = []
                this.cityDisabled = true
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.cityDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "cityList", "city")
            } else {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.city = []
                this.region = []
                this.stree = []
                this.project = []
            }
        },
        // 城市选择
        changeCity(code) {
            if (code.length >= 2) {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.regionDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region")
            } else {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
            }
        },
        // 区选择
        changeRegion(code) {
            if (code.length >= 2) {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.streeDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
            } else {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
            }
        },
        // 街道选择
        changeStree(code) {
            if (code.length >= 2) {
                this.projectList = []
                this.project = []
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.projectDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project")
            } else {
                this.projectList = []
                this.project = []
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            // if (code == "CN") {
            //     this[list] = [{ value: "44", label: "广东省" }]
            //     return
            // }
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        }
                    })
                    if (res.dataList.length == "1") {
                        this[model] = [res.dataList[0].orgPath]
                    }
                }
            })
        },
        changeSelectForm() {
            // this.$nextTick(()=>{
            this.formData.selectForm = true
            // })
        },
        quoteData() {
            let data = this.formDataObj //
            let formDataObj = {}
            console.log("引用的data", data)
            formDataObj = {
                title: data.title,
                recommend: data.recommend && data.recommend != "" ? data.recommend : "0",
                registered_card: data.registered_card, //是否生成排号
                pushChannel: data.pushChannel === "4" ? true : data.pushChannel === "" ? false : false, //小程序订阅通知
                time: [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                contactMobile: data.contactMobile,
                terminal: ["1", "3", "4"],
                photo: data.photo,
                content: data.content,
                time2:
                    data.appointmentVo.appointmentType && data.appointmentVo.appointmentType == "1"
                        ? [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24)]
                        : [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                whetherDynamicForm: data.whetherDynamicForm,
                repetition: data.appointmentVo.repetition,
                newRepetition: data.appointmentVo.repetition,
                whetherRegistration: data.appointmentVo.whetherRegistration,
                registrationType: data.appointmentVo.registrationType,
                whetherSign: data.appointmentVo.whetherSign,
                whetherCrypto: data.appointmentVo.whetherCrypto,
                appointmentType: data.appointmentVo.appointmentType ? data.appointmentVo.appointmentType : "1",
                // recommend:data.appointmentVo.recommend,
                perCapita: data.appointmentVo.perCapita !== null ? Number(data.appointmentVo.perCapita) : null,
                addr: data.appointmentVo.addr,
                range: data.appointmentVo.range,
                venue: data.appointmentVo.venue,
                venueTime: data.appointmentVo.venueTime ? new Date(data.appointmentVo.venueTime) : "",
                source: parent.vue.loginInfo.userinfo.orgName,
                selectForm: data.formDetailVo ? true : false,
                buttonName: "",
            }

            if (data.appointmentVo.startDate && data.appointmentVo.startDate == data.appointmentVo.endDate) {
                this.disabledNorepetition = false
                this.disabledRepetition = true
            } else {
                this.disabledNorepetition = true
                this.disabledRepetition = false
            }
            if (data.formDetailVo) {
                this.formDetailVo = data.formDetailVo
                this.formDetailVo.id = this.$core.randomString()
                this.changeFormShow = true // 切换为表单修改组件
            }
            if (data.ruleVos) {
                data.ruleVos.map(item => {
                    if (item.ruleCode == "authentication") {
                        this.ruleVos[0] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue && item.ruleValue != "" ? (item.ruleValue == "true" ? "1" : item.ruleValue == "false" ? "2" : "") : "",
                        }
                    } else if (item.ruleCode == "participate_integral") {
                        this.ruleVos[1] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue ? Number(item.ruleValue) : "",
                        }
                    } else if (item.ruleCode == "button_name") {
                        if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                            this.ruleVos[2] = {
                                ruleCode: item.ruleCode,
                                ruleValue: item.ruleValue,
                            }
                        } else {
                            this.ruleVos[2] = {
                                ruleCode: item.ruleCode,
                                ruleValue: "自定义",
                            }
                            formDataObj.buttonName = item.ruleValue
                        }
                    }

                    // else if (item.ruleCode == "button_name") {
                    //     if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                    //         this.ruleVos[2] = {
                    //             ruleCode: item.ruleCode,
                    //             ruleValue: item.ruleValue,
                    //         }
                    //     } else {
                    //         this.ruleVos[2] = {
                    //             ruleCode: item.ruleCode,
                    //             ruleValue: "其他",
                    //         }
                    //         this.formData.buttonName = item.ruleValue
                    //     }
                    // }
                    else if (item.ruleCode == "whether_period") {
                        this.ruleVos[3] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "multiple_period") {
                        this.ruleVos[4] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "registered_card") {
                        this.ruleVos[5] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "repetition_type") {
                        this.ruleVos[6] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "join_number_limit") {
                        if (item.ruleValue == "n") {
                            this.ruleVos[7] = {
                                ruleCode: item.ruleCode,
                                ruleValue: 0,
                            }
                        } else {
                            this.ruleVos[7] = {
                                ruleCode: item.ruleCode,
                                ruleValue: item.ruleValue,
                            }
                        }
                    } else if (item.ruleCode == "SUCCESS_IMAGE") {
                        this.ruleVos[8] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    } else if (item.ruleCode == "SUCCESS_DESC") {
                        this.ruleVos[9] = {
                            ruleCode: item.ruleCode,
                            ruleValue: item.ruleValue,
                        }
                    }
                })
            }
            if (this.formDetailVo && this.formDetailVo.id) {
                this.changeFromItemId()
            }
            if (data.cycleVo && (data.appointmentVo.repetition == "1" || data.appointmentVo.repetition == "2")) {
                data.cycleVo.cycleDates.map(item => {
                    this.cycleDates.push(Number(item.dateValue))
                })
            }
            if (data.cycleVo) {
                this.cycleTimes = data.cycleVo.cycleTimes.map(item => {
                    return {
                        limits: [
                            {
                                attrType: "person",
                                attrValue: item.limits[0].attrValue,
                            },
                        ],
                        timeValue: item.timeValue.split("-"),
                    }
                })
            }
            this.appointmentId = data.appointmentVo.id
            if (this.formData.repetition == "0" || this.formData.repetition == "3") {
                this.disabledStatus = false
            } else {
                this.disabledStatus = true
            }
            if (this.addType == "quote") {
                this.disabledNorepetition = false
                this.disabledRepetition = false
                this.disabledStatus = false
            }
            if (this.addType == "copy") {
                this.disabledStatus = false
                this.editDisabled = false
            }
            if (data.pushChannelVo && data.pushChannelVo.pushType) {
                formDataObj.pushChannelVo = data.pushChannelVo
                this.pushType = data.pushChannelVo.pushType && data.pushChannelVo.pushType != "" ? data.pushChannelVo.pushType.split(",") : []
            }
            if (!data.luckDraw || data.luckDraw == "0") {
                formDataObj.luckDraw = "0"
                formDataObj.prizeVos = [
                    {
                        itemName: "",
                        itemNum: 1,
                    },
                ]
            }
            this.formData = formDataObj
            console.log("最终的数据", this.formData, this.ruleVos)
        },

        // 图片模板
        selectTemplate() {
            if (this.imgTemplateList.length == 0) {
                this.$get("/datamsg/api/pc/fileUpload/selectFileUploadPage", {
                    groupId: 10000,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    fileType: "image",
                    page: 1,
                    pageSize: 100,
                }).then(res => {
                    if (res.code == 200) {
                        this.imgTemplateList = res.dataList
                    }
                })
            }
            this.imgStatus = true
        },
        changeImgStatus(val) {
            this.imgStatus = val
        },
        selectImg(path) {
            this.formData.photo = path
            this.imgStatus = false
        },
        changeRuleVos2(val) {
            this.ruleVos.splice(2, 1, {
                ruleCode: "button_name", //按钮名称
                ruleValue: val,
            })
        },
        changeFromItemId() {
            let formObj = JSON.parse(JSON.stringify(this.formDetailVo))
            // 保存之前的id
            let data = []
            // 保存更改之后的id
            let changeData = []
            formObj.interactionFormVos.map(item => {
                data.push(item.fieldId)
                item.fieldId = this.$core.randomString()
                changeData.push(item.fieldId)
            })

            formObj.id = this.$core.randomString()
            formObj.interactionFormVos.forEach(e => {
                e.fieldParamVos.forEach(item => {
                    item.id = this.$core.randomString()
                    item.fieldId = this.$core.randomString()
                })
                if (e.list && e.list.length != 0) {
                    e.list.forEach(oa => {
                        oa.field = this.$core.randomString()
                        let linkageFieldId = ""
                        if (oa.linkageFieldId && oa.linkageFieldId != "") {
                            data.map((item, index) => {
                                if (oa.linkageFieldId.search(item) != -1) {
                                    linkageFieldId = linkageFieldId + changeData[index] + ","
                                }
                            })
                        }
                        oa.linkageFieldId = linkageFieldId.charAt(linkageFieldId.length - 1) == "," ? linkageFieldId.substring(0, linkageFieldId.length - 1) : linkageFieldId
                    })
                }
            })

            this.formDetailVo = formObj
            console.log("数据提交之前，重新生成所有的id", this.formDetailVo)
        },
    },
    async created() {
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
        await this.getPulishData("CN", "proList", "prov")
        // await this.getPulishData("44", "cityList", "city")
        if (this.activeId) {
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId: this.activeId,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                terminal: "2",
            })
                .then(res => {
                    if (res.code == 200 && res.data) {
                        if (this.addType != "copy") {
                            if (res.data.dataScopeList.length == 1) {
                                //回显发布范围
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 1: 
                                            this.prov = item
                                            break
                                        case 2:
                                            this.prov = arr[0]
                                            this.city = item
                                            break
                                        case 3:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = item
                                            break
                                        case 4:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = item
                                            break
                                        case 5:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                                            this.project = item
                                            break
                                    }
                                })
                            } else if (res.data.dataScopeList.length > 1) {
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 1:
                                            this.prov.push(item)
                                            break
                                        case 2:
                                            this.prov.push(arr[0])
                                            this.city.push(item)
                                            break
                                        case 3:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(item)
                                            break
                                        case 4:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(item)
                                            break
                                        case 5:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                                            this.project.push(item)
                                            break
                                    }
                                })
                                this.prov = Array.from(new Set(this.prov))
                                this.city = Array.from(new Set(this.city))
                                this.region = Array.from(new Set(this.region))
                                this.stree = Array.from(new Set(this.stree))
                                this.project = Array.from(new Set(this.project))
                            }
                        }

                        this.formData = {
                            title: res.data.title,
                            recommend: res.data.recommend && res.data.recommend != "" ? res.data.recommend : "0",
                            registered_card: res.data.registered_card, //是否生成排号
                            pushChannel: res.data.pushChannel === "4" ? true : res.data.pushChannel === "" ? false : false, //小程序订阅通知
                            time:
                                this.addType != "copy"
                                    ? res.data.startTime && res.data.endTime
                                        ? [new Date(res.data.startTime), new Date(res.data.endTime)]
                                        : ["", ""]
                                    : [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                            contactMobile: res.data.contactMobile,
                            terminal: this.addType != "copy" ? (res.data.terminal.split(",").indexOf("0") != -1 ? ["0", "1", "2", "3", "4", "5"] : res.data.terminal.split(",")) : ["1", "3", "4"],
                            photo: res.data.photo,
                            content: res.data.content,
                            time2:
                                this.addType != "copy"
                                    ? res.data.appointmentVo.startDate && res.data.appointmentVo.endDate
                                        ? [new Date(res.data.appointmentVo.startDate), new Date(res.data.appointmentVo.endDate)]
                                        : ["", ""]
                                    : [new Date(), new Date().setTime(new Date().getTime() + 3600 * 1000 * 24 * 365 * 5)],
                            whetherDynamicForm: res.data.whetherDynamicForm,
                            repetition: res.data.appointmentVo.repetition,
                            newRepetition: res.data.appointmentVo.repetition,
                            whetherRegistration: res.data.appointmentVo.whetherRegistration,
                            registrationType: res.data.appointmentVo.registrationType,
                            whetherSign: res.data.appointmentVo.whetherSign,
                            whetherCrypto: res.data.appointmentVo.whetherCrypto,
                            appointmentType: res.data.appointmentVo.appointmentType ? res.data.appointmentVo.appointmentType : "1",
                            // recommend:res.data.appointmentVo.recommend,
                            perCapita: res.data.appointmentVo.perCapita !== null ? Number(res.data.appointmentVo.perCapita) : null,
                            addr: res.data.appointmentVo.addr,
                            range: res.data.appointmentVo.range,
                            venue: res.data.appointmentVo.venue,
                            venueTime: res.data.appointmentVo.venueTime ? new Date(res.data.appointmentVo.venueTime) : "",
                            source: parent.vue.loginInfo.userinfo.orgName,
                            selectForm: res.data.formDetailVo ? true : false,
                            luckDraw: res.data.luckDraw ? res.data.luckDraw : res.data.prizeVos && res.data.prizeVos.length ? "1" : "0",
                            prizeVos: res.data.prizeVos && res.data.prizeVos.length ? res.data.prizeVos : [{ itemName: "", itemNum: 1 }],
                            joinUser: res.data.joinUser ? res.data.joinUser : 0,
                        }
                        this.editDisabled = res.data.joinUser && res.data.joinUser != "" && res.data.joinUser > 0 ? true : false
                        // if(this.formData.repetition == '0') {
                        //   this.disabledNorepetition = false;
                        //   this.disabledRepetition = true;
                        // }
                        if (res.data.appointmentVo.startDate && res.data.appointmentVo.startDate == res.data.appointmentVo.endDate) {
                            this.disabledNorepetition = false
                            this.disabledRepetition = true
                        } else {
                            this.disabledNorepetition = true
                            this.disabledRepetition = false
                        }
                        if (res.data.formDetailVo) {
                            this.formDetailVo = res.data.formDetailVo
                            this.changeFormShow = true // 切换为表单修改组件
                        }
                        if (res.data.ruleVos) {
                            res.data.ruleVos.map(item => {
                                if (item.ruleCode == "authentication") {
                                    this.ruleVos[0] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue && item.ruleValue != "" ? (item.ruleValue == "true" ? "1" : item.ruleValue == "false" ? "2" : "") : "",
                                    }
                                } else if (item.ruleCode == "participate_integral") {
                                    this.ruleVos[1] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue ? Number(item.ruleValue) : "",
                                    }
                                } else if (item.ruleCode == "button_name") {
                                    if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                                        this.ruleVos[2] = {
                                            ruleCode: item.ruleCode,
                                            ruleValue: item.ruleValue,
                                        }
                                    } else {
                                        this.ruleVos[2] = {
                                            ruleCode: item.ruleCode,
                                            ruleValue: "自定义",
                                        }
                                        this.formData.buttonName = item.ruleValue
                                    }
                                } else if (item.ruleCode == "whether_period") {
                                    this.ruleVos[3] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "multiple_period") {
                                    this.ruleVos[4] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "registered_card") {
                                    this.ruleVos[5] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "repetition_type") {
                                    this.ruleVos[6] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "join_number_limit") {
                                    if (item.ruleValue == "n") item.ruleValue = 0
                                    else {
                                        this.ruleVos[7] = {
                                            ruleCode: item.ruleCode,
                                            ruleValue: item.ruleValue,
                                        }
                                    }
                                } else if (item.ruleCode == "SUCCESS_IMAGE") {
                                    this.ruleVos[8] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else if (item.ruleCode == "SUCCESS_DESC") {
                                    this.ruleVos[9] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                }
                            })
                        }
                        if (res.data.cycleVo && (res.data.appointmentVo.repetition == "1" || res.data.appointmentVo.repetition == "2")) {
                            res.data.cycleVo.cycleDates.map(item => {
                                this.cycleDates.push(Number(item.dateValue))
                            })
                        }
                        if (res.data.cycleVo && res.data.cycleVo.cycleTimes) {
                            this.cycleTimes = res.data.cycleVo.cycleTimes.map(item => {
                                return {
                                    limits: [
                                        {
                                            attrType: "person",
                                            attrValue: item.limits[0].attrValue,
                                        },
                                    ],
                                    timeValue: item.timeValue.split("-"),
                                }
                            })
                        }
                        this.appointmentId = res.data.appointmentVo.id
                        if (this.formData.repetition == "0" || this.formData.repetition == "3") {
                            this.disabledStatus = false
                        } else {
                            this.disabledStatus = true
                        }

                        if (this.addType == "quote" || this.addType == "copy") {
                            this.disabledNorepetition = false
                            this.disabledRepetition = false
                            this.disabledStatus = false
                        }
                        if (this.addType == "copy") {
                            this.disabledStatus = false
                            this.editDisabled = false
                        }
                        if (this.addType == "copy") {
                            if (this.formDetailVo && this.formDetailVo.id) {
                                let formObj = JSON.parse(JSON.stringify(this.formDetailVo))
                                // 保存之前的id
                                let data = []
                                // 保存更改之后的id
                                let changeData = []
                                formObj.interactionFormVos.map(item => {
                                    data.push(item.fieldId)
                                    item.fieldId = this.$core.randomString()
                                    changeData.push(item.fieldId)
                                })

                                formObj.id = this.$core.randomString()
                                formObj.interactionFormVos.forEach(e => {
                                    e.fieldParamVos.forEach(item => {
                                        item.id = this.$core.randomString()
                                        item.fieldId = this.$core.randomString()
                                    })
                                    if (e.list && e.list.length != 0) {
                                        e.list.forEach(oa => {
                                            oa.field = this.$core.randomString()
                                            let linkageFieldId = ""
                                            if (oa.linkageFieldId && oa.linkageFieldId != "") {
                                                data.map((item, index) => {
                                                    if (oa.linkageFieldId.search(item) != -1) {
                                                        linkageFieldId = linkageFieldId + changeData[index] + ","
                                                    }
                                                })
                                            }
                                            oa.linkageFieldId = linkageFieldId.charAt(linkageFieldId.length - 1) == "," ? linkageFieldId.substring(0, linkageFieldId.length - 1) : linkageFieldId
                                        })
                                    }
                                })

                                this.formDetailVo = formObj
                            }
                        } else {
                            if (res.data.pushChannelVo && res.data.pushChannelVo.pushType) {
                                this.formData.pushChannelVo = res.data.pushChannelVo
                                this.pushType = res.data.pushChannelVo.pushType && res.data.pushChannelVo.pushType != "" ? res.data.pushChannelVo.pushType.split(",") : []
                            }
                            if (res.data.groupList && res.data.groupList.length != 0) {
                                let arr = []
                                res.data.groupList.map(item => {
                                    arr.push(item.groupName)
                                })
                                this.formData.groupNames = arr.join(",")
                                this.formData.channels = res.data.pushChannelVo.channels
                                this.$refs.checkGroup.rightList = res.data.groupList
                            }
                        }

                        // var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
                        // v_Obj.setContent(this.formData.content); //写入编辑器富文本内容
                        this.$Message.destroy()
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        }
    },
    watch: {
        pushType: {
            handler(val) {
                this.formData.pushType = val
            },
            immediate: true,
        },
    },

    components: {
        Mymodel,
        LiefengEditor,
        AddForm,
        LiefengModal,
        ChangeForm,
        PreviewForm,
        CheckGroup,
    },
}
</script>

<style scoped lang='less'>
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        flex: 1;
        height: 100%;
    }
}
/deep/.ivu-tooltip-inner {
    max-width: 100%;
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.dateTime {
    width: 372px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        width: 50px;
        height: 40px;
        line-height: 40px;
        margin: 6px;
        text-align: center;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #5cadff;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.dateWeek {
    width: 550px;
    display: inline-flex;
    flex-wrap: nowrap;
    text-align: center;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        flex: 1;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #5cadff;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.tempContainer {
    /deep/.ivu-tabs-content {
        height: calc(100vh - 122px);
        .tempBox {
            height: calc(100% - 35px);
            overflow: auto;
            min-height: 200px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            & > div {
                width: 32%;
                height: 33%;
                min-height: 211px;
                margin: 0.5%;
                .ivu-card-head {
                    padding: 10px;
                    text-align: center;
                }
                .ivu-card-body {
                    padding: 10px;
                    line-height: 30px;
                    min-height: 142px;
                    height: 100%;
                    overflow: auto;
                }
            }
            .noList {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
/deep/.ivu-input[disabled],
/deep/.ivu-input-number-disabled,
/deep/.ivu-input-number-input[disabled],
/deep/fieldset[disabled] .ivu-input,
/deep/.ivu-checkbox-disabled .ivu-checkbox-inner,
/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,
/deep/.ivu-select-disabled .ivu-select-selection,
/deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff;
    color: #333;
}
.cententbtn {
    position: relative;
    padding: 100px 0 0 300px;
    .cover {
        position: absolute;
        width: 50px;
        height: 50px;
        z-index: 9;
        background-color: #fff;
        top: 100px;
        left: 270px;
    }
    span {
        display: inline-block;
        width: 150px;
        height: 80px;
        line-height: 80px;
        font-size: 20px;
        background-color: #2d8cf0;
        text-align: center;
        color: #fff !important;
        border-radius: 5px;
    }
}
.imgbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    .imgitem {
        margin: 5px;
        width: 270px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 8px rgb(233, 61, 61);
        }
        img {
            width: 100%;
        }
    }
}
.luckDraw-div {
    .title {
        .span-div {
            display: inline-block;
            width: 200px;
        }
        .span2 {
            margin-left: 20px;
        }
    }
}
</style>
