<template>
    <Form :model="rightData" :label-colon="true" :disabled="disabledForm">
    <FormItem label="标题">
      <Input v-model.trim="rightData.title" @on-change="changeItemInfo" :maxlength="200"></Input>
    </FormItem>
    <FormItem label="填写说明">
      <Input
      :maxlength="200"
        v-model.trim="rightData.placeholder"
        placeholder="对标题进一步描述说明"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
     <FormItem>
      <Checkbox v-model="rightData.nowTime" @on-change="changeItemInfo('time')">默认当前时间</Checkbox>
    </FormItem>
     <FormItem>
      <Checkbox v-model="rightData.required" @on-change="changeItemInfo">必填</Checkbox>
    </FormItem>
    </Form>
</template>

<script>
   export default {
       props: ['rightData',"disabledForm"],
        methods: {
             changeItemInfo(name) {
                 if(name == 'time') {
                    this.rightData.value = new Date();
                 }
                this.$emit("changeItemInfo", this.rightData);
            }
        }
    }
</script>
    
<style scoped lang='less'>
    
</style>