<template>
  <Form :model="rightData" :label-colon="true" :disabled="disabledForm">
    <FormItem label="标题">
      <Input v-model.trim="rightData.title" :maxlength="200" @on-change="changeItemInfo"></Input>
    </FormItem>
    <FormItem label="填写说明">
      <Input
      :maxlength="200"
        v-model.trim="rightData.placeholder"
        placeholder="对标题进一步描述说明"
        @on-change="changeItemInfo"
      ></Input>
    </FormItem>
    <FormItem label="最小值">
      <InputNumber :min="0" :max="999999999999" v-model.trim="rightData.min" @on-change="changeItemInfo" style="width: 100%"></InputNumber>
    </FormItem>
    <FormItem label="最大值">
      <InputNumber :min="0" :max="999999999999" v-model.trim="rightData.max" @on-change="changeItemInfo" style="width: 100%"></InputNumber>
    </FormItem>
    <FormItem label="步长">
      <InputNumber v-model.trim="rightData.step" :min="0" @on-change="changeItemInfo" style="width: 100%"></InputNumber>
    </FormItem>
    <FormItem label="小数位">
      <InputNumber 
        :max="100" 
        :min="0"
        v-model.trim="rightData.precision"
        style="width: 100%"
        @on-change="changeItemInfo"
      ></InputNumber>
    </FormItem>
    <FormItem label="单位">
      <Select v-model.trim="rightData.unit" transfer filterable allow-create @on-change="changeItemInfo" @on-create="handleCreate">
        <Option
          v-for="item in unitList"
          :value="item.label"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select>
    </FormItem>

    <FormItem>
      <Checkbox v-model="rightData.required" @on-change="changeItemInfo"
        >必填</Checkbox
      >
    </FormItem>
  </Form>
</template>

<script>
   export default {
       props: ['rightData',"disabledForm"],
       data() {
            return {
              unitList: []
           }
        },
         methods: {
            //  创建单位
             handleCreate(val) {
                 this.unitList.push({
                     value: val,
                     label: val
                 })
             }, 
            changeItemInfo(params) {
                this.$emit("changeItemInfo", this.rightData);
            }
        },
        created() {
            this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: "FORM_DYNAMIC_UNIT",
            }).then((res) => {
                if (res.code == 200) {
                    this.unitList = res.dataList.map(item => {
                        return {
                            value: item.dictKey.toString(),
                            label: item.dictValue
                        }
                    });
                 }
                })
        }
   }
      
</script>
    
<style scoped lang='less'>
</style>