<template>
    <Form :model="rightData" :label-colon="true" :disabled="disabledForm">
        <FormItem label="标题">
            <Input v-model.trim="rightData.title" :maxlength="200" @on-change="changeItemInfo"></Input>
        </FormItem>
        <FormItem label="填写说明">
            <Input :maxlength="200" v-model="rightData.placeholder" placeholder="对标题进一步描述说明" @on-change="changeItemInfo"></Input>
        </FormItem>
        <FormItem label="校验类型">
            <Select v-model="rightData.ruleType" transfer @on-change="changeItemInfo">
                <Option v-for="item in ruleTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </FormItem>
        <FormItem>
            <Checkbox v-model="rightData.required" @on-change="changeItemInfo">必填</Checkbox>
        </FormItem>
        <FormItem v-if="'list' in rightData">
            <div slot="label">
                设置需过滤的{{ rightData.text && rightData.text != "" ? (rightData.text.indexOf("手机号") != -1 ? "手机号" : "身份证号") : "" }}清单 :
                <span style="color: #aaa">(换行设置多个)</span>
            </div>
            <Input type="textarea" :rows="4" :maxlength="12000" v-model="textarea" :placeholder="rightData.text && rightData.text != '' ? (rightData.text.indexOf('手机号') != -1 ? '过滤的手机号将提示不满足参与条件' : '过滤的身份证号将提示不满足参与条件') : ''" @on-change="changeItemInfo"></Input>
        </FormItem>
        <FormItem label="过滤提示说明" v-if="'valiteText' in rightData">
            <Input :maxlength="200" v-model="rightData.valiteText" placeholder="您不满足参与条件" @on-change="changeItemInfo"></Input>
        </FormItem>
    </Form>
</template>

<script>
export default {
    props: ["rightData", "disabledForm"],
    data() {
        return {
            ruleTypeList: [],
            textarea: "",
            textareaId: [], //修改回显时，先保存textarea的id，否则保存时后端会丢失数据
        }
    },
    methods: {
        changeItemInfo() {
            this.$emit("changeItemInfo", this.rightData)
        },
    },
    created() {
        console.log(this.rightData)
        if (this.rightData.optionList && this.rightData.optionList.length > 0) {
            let arr = []
            this.textareaId = []
            this.rightData.optionList.map(item => {
                arr.push(item.label)
                this.textareaId.push(item.id)
            })
            this.textarea = arr.join("\n")
        }
    },
    watch: {
        textarea(newV, oldV) {
            if (newV !== oldV) {
                let arr = newV.split("\n")
                let length = 0
                this.rightData.optionList = []
                arr.map((item, index) => {
                    if (this.textareaId.length > 0) {
                        //证明此事是回显数据
                        if (this.textareaId.length >= arr.length) {
                            this.rightData.optionList.push({
                                value: (length += 1),
                                label: item,
                                id: this.textareaId[index],
                            })
                        } else {
                            let randomString = this.$core.randomString()
                            this.rightData.optionList.push({
                                value: (length += 1),
                                label: item,
                                id: randomString,
                            })
                            this.textareaId.push(randomString)
                        }
                    } else {
                        if (item != "" && item != undefined && item != null) {
                            this.rightData.optionList.push({
                                value: (length += 1),
                                label: item,
                                id: this.$core.randomString(),
                            })
                        }
                    }
                })
            }
        },
    },
    beforeMount() {
        this.$get("/datamsg/api/common/sys/findDictByDictType", {
            dictType: "FORM_AUTHENTICATION",
        }).then(res => {
            if (res.code == 200) {
                this.ruleTypeList = res.dataList.map(item => {
                    if (item.dictValue == "不校验" && !this.rightData.ruleType) {
                        this.rightData.ruleType = item.dictKey
                        this.changeItemInfo()
                    }
                    return {
                        value: item.dictKey,
                        label: item.dictValue,
                    }
                })
            } else {
                this.$Message.error({
                    background: true,
                    content: res.desc,
                })
            }
        })
    },
}
</script>
    
<style scoped lang='less'>
</style>